import React, { useEffect, useState, useRef } from "react";
import url from "../../baseUrl";
import Providers from "../../utilities/getServiceProviders";
import { CiCircleRemove } from "react-icons/ci";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";

const Queries = ({inititaliazeFetchQueries, setInitializeFetchQueries}) => {
  const [queries, setQueries] = useState({});
  const [loadQueries, setLoadQueries] = useState(true);
  const [activeQuery, setActiveQuery] = useState({});
  const [companies, setCompanies] = useState([]);
  const [activeCompany, setActiveCompany] = useState("");
  const [serviceproviders, setServiceProviders] = useState([]);
  const [assignBtnLoad, setAssignBtnLoad] = useState({
    load: false,
    index: null,
  });
  const [autoBtnLoad, setAutoBtnLoad] = useState({
    load: false,
    index: null,
  });
  const [filter, setFilter] = useState(false);
  const [transfer, setTransfer] = useState(false)
  const [filteredQueries, setFiltredQueries] = useState({});
  const startInputFilter = useRef(null);
  const endInputFilter = useRef(null);
  const adminType = JSON.parse(localStorage.getItem("admin")).adminType;

  const handleActiveQuery = (_id) => {
    const filteredQueries = queries.filter((query, index) => {
      if (query._id == _id) {
        setAssignBtnLoad({
          load: false,
          index: index,
        });

        setAutoBtnLoad({
          load: false,
          index: index,
        })

        return query;
      }
    });
    setActiveQuery(filteredQueries[0]);
  };

  // close the pop up for selecting service provider
  const removePopUp = () => {
    setActiveQuery({});
    setTransfer(false)
  };

  // handle active company

  const handleActiveCompany = (e) => {
    const value = e.target.value;
    setActiveCompany(value);
    setQueries([]);
  };

  useEffect(() => {
    getQueries();
    getServiceProviders();
    getCompanies();
  }, []);

  useEffect(() => {
    getQueries();
  }, [activeCompany]);

  // get queries when query notification is recieved
  useEffect(() => {
    getQueries();
    console.log("fetched queries")
    setInitializeFetchQueries(false)
  }, [inititaliazeFetchQueries])

  // get all queries
  async function getQueries() {
    setLoadQueries(true);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(
      `${url.mainUrl}/admin/query/${
        activeCompany && `?company=${activeCompany}`
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
      }
    );

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setQueries(result.queries);
    setLoadQueries(false);
  }

  // getCompanies
  async function getCompanies() {
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/admin/company`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setCompanies(result.companies);
  }

  // get service providers
  async function getServiceProviders() {
    const service_providers = await Providers();
    if (service_providers.message) {
      setServiceProviders(service_providers.serviceProviders);
    }
  }

  // assign service provider manually
  async function assignServiceProviderManually(service_provider) {
    setAssignBtnLoad({
      ...assignBtnLoad,
      load: true,
    });
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(
      `${url.mainUrl}/admin/serviceprovider/assignmanually/${activeQuery._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
        body: JSON.stringify({
          serviceProvider: service_provider,
        }),
      }
    );

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      setAssignBtnLoad({
        load: false,
      });
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

    setAssignBtnLoad({
      load: false,
    });

    await getQueries();
    await getServiceProviders();
  }

  // assign service provider automatically
  async function assignServiceproviderAutomatically(query, index) {
    setAutoBtnLoad({
      load: true,
      index: index,
    });
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(
      `${url.mainUrl}/admin/serviceprovider/assignautomatically/${query}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
      }
    );

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      setAutoBtnLoad({
        load: false,
        index: index,
      });
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });

    setAutoBtnLoad({
      load: false,
      index: index,
    });

    await getQueries();
    await getServiceProviders();
  }

  const handleConfirmManualAssignMent = (service_provider) => {
    const closeToast = () => toast.dismiss();
    const serviceProvider = serviceproviders.filter(
      (provider) => provider._id == service_provider
    );
    toast.warn(
      <div>
        <p>Are you sure you want to assign job to {serviceProvider[0].firstName}?</p>
        <div className="flex justify-evenly mt-3">
          <button
            onClick={() => {
              assignServiceProviderManually(service_provider);
              closeToast();
              removePopUp();
            }}
            className="py-1 px-3 bg-green-500 rounded-md text-white"
          >
            Accept
          </button>
          <button className="py-1 px-3 bg-danger rounded-md text-white">
            Decline
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  };

  const handleConfirmAutomaticAssignment = (query, index) => {
    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>
          When automatic assignment is selected, notifications will be sent to
          all service providers who are not booked, and the first one to accept
          will be assigned the job automatically
        </p>
        <div className="flex justify-evenly mt-3">
          <button
            onClick={() => {
              assignServiceproviderAutomatically(query, index);
              closeToast();
              removePopUp();
            }}
            className="py-1 px-3 bg-green-500 rounded-md text-white"
          >
            Accept
          </button>
          <button className="py-1 px-3 bg-danger rounded-md text-white">
            Decline
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  };


  // confirm request forward
  const confirmRequestForward = (company, index) => {
    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>
          Are you sure you wanto to Forward request to <span className="font-bold">{company.name}</span>?
        </p>
        <div className="flex justify-evenly mt-3">
          <button
            onClick={() => {
              handleRequestForward(company._id);
              closeToast();
              removePopUp();
            }}
            className="py-1 px-3 bg-green-500 rounded-md text-white"
          >
            Accept
          </button>
          <button className="py-1 px-3 bg-danger rounded-md text-white">
            Decline
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  }

  //handle Query forwarding
  const handleRequestForward = async (company, index) => {
    try{
      setAutoBtnLoad(()=> {
        let data = {...autoBtnLoad}
        data.load = true

        return data
      })
      const admin = JSON.parse(localStorage.getItem("admin"));
      const response = await fetch(`${url.mainUrl}/admin/query/transfer`, {
        method: "POST",
        body: JSON.stringify({
          queryId: activeQuery._id,
          companyId: company
        }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": admin.access_token
        }
      })

      if(!response.ok){
        const result = await response.json()
        toast.error(result.error, {
          position: toast.POSITION.TOP_CENTER
        })
        setAutoBtnLoad(()=> {
          let data = {...autoBtnLoad}
          data.load = false
  
          return data
        })
        setTransfer(false)
        return
      }

      const result = await response.json()
      toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER
      })


      setTransfer(false)
      setAutoBtnLoad(()=> {
        let data = {...autoBtnLoad}
        data.load = false

        return data
      })

      await getQueries()


    }
    catch(error){
      setTransfer(false)
      toast.error("Could not complete Request forwarding", {
        position: toast.POSITION.TOP_CENTER
      })

      setAutoBtnLoad(()=> {
        let data = {...autoBtnLoad}
        data.load = false

        return data
      })
    }
  }

  // filter queries
  function handleSort(e) {
    const startDate = startInputFilter.current.value;
    const endDate = endInputFilter.current.value;

    if (!startDate && !endDate) {
      setFilter(false);
      return;
    }

    if (new Date(endDate) < new Date(startDate)) {
      toast.error("End date can not be less than Start date", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        closeButton: true,
      });

      return;
    }

    setFilter(true);

    if (startDate) {
      const sortStart = new Date(startDate);

      const filtered = queries.filter((query) => {
        const queryDate = new Date(query.createdAt);
        if (queryDate > sortStart) {
          return query;
        }
      });

      setFiltredQueries(filtered);
    }

    if (endDate) {
      const sortStart = new Date(endDate);

      const filtered = queries.filter((query) => {
        const queryDate = new Date(query.createdAt);
        if (queryDate < sortStart) {
          return query;
        }
      });

      setFiltredQueries(filtered);
    }

    if (startDate && endDate) {
      const sortStartDate = new Date(startDate);
      const sortEndDate = new Date(endDate);

      const filtered = queries.filter((query) => {
        const queryDate = new Date(query.createdAt);
        if (queryDate < sortEndDate && queryDate > sortStartDate) {
          return query;
        }
      });

      setFiltredQueries(filtered);
    }
  }

  return (
    <section className="h-full relative">
      <div className="border border-primary rounded-md h-full flex flex-col">
        <h1 className="text-xl text-primary py-2 px-10 shadow-md">
          All Queries
        </h1>
        <div className="py-2 ">
          <div className="flex flex-col px-5 gap-5 py-2">
            {adminType == "super" && (
              <div>
                <select
                  name="service"
                  id="service"
                  onChange={handleActiveCompany}
                  className="w-1/2 block mx-auto px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                >
                  <option selected value={""}>
                    All
                  </option>
                  {companies.length &&
                    companies.map((company) => {
                      return (
                        <option
                          key={company._id}
                          value={company._id}
                          className="py-2"
                        >
                          {company.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}

            <div className="flex">
              <div className="flex-1 p-2">
                <label htmlFor="fromDate">From</label>
                <input
                  onChange={handleSort}
                  ref={startInputFilter}
                  name="startDate"
                  type="date"
                  htmlFor="fromDate"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                />
              </div>
              <div className="flex-1 p-2">
                <label htmlFor="fromDate">To</label>
                <input
                  onChange={handleSort}
                  ref={endInputFilter}
                  name="endDate"
                  type="date"
                  htmlFor="fromDate"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                />
              </div>
            </div>
          </div>
          <div className="">
            {filter && (
              <p className="text-center">
                Number of Queries found:{" "}
                <span className="font-bold">{filteredQueries.length}</span>
              </p>
            )}
          </div>
        </div>
        <div className="px-5 rounded-md flex-1 overflow-y-auto relative">
          {loadQueries && (
            <div className="absolute left-[50%] top-[50%] translate-x-[50%]">
              <PropagateLoader color="#B7926B" loading={true} size={15} />
            </div>
          )}

          {queries.length > 0 &&
            (() => {
              let data;
              filter
                ? (data = filteredQueries.map((query, index) => {
                    return (
                      <div
                        key={query._id}
                        className="h-[150px] border border-primary shadow-md my-2 p-2 rounded-md text-primary flex justify-evenly"
                      >
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Name
                          <span className="font-[500]">
                            {query.requester.firstName + " " + query.requester.lastName}
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Service Category
                          <span className="font-[500]">
                            {query.serviceType.name}
                          </span>
                        </p>
                        {
                          adminType == "super" ?

                          <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                            Fee charged
                            <span className="font-[500]">
                              {query.amount}
                            </span>
                          </p>
                          : ""
                        }
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Location
                          <span className="font-[500]">{query.location}</span>
                        </p>

                        {
                          adminType == "super" ?

                          <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                            Phone
                            <span className="font-[500]">
                              {query.requester.phone || "--"}
                            </span>
                          </p>
                          : ""
                        }
                        
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Requested Date
                          <span className="font-[500]">
                            {formatISODate(query.createdAt, true).date}{" "}
                            {formatISODate(query.createdAt, true).time}
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Service Date
                          <span className="font-[500]">
                            {
                              formatISODate(query.date || query.duration.from)
                                .date
                            }{" "}
                            {
                              formatISODate(query.date || query.duration.from)
                                .time
                            }
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Assigned To
                          <span className="font-[500]">
                            {query.company ? query.company.name : "--"}
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Status
                          <span
                            className={`font-[800] ${
                              query.status == "placed" &&
                              "inline-block text-center h-6"
                            }`}
                          >
                            {query.status}
                            {query.status == "placed" && (
                              <p className="flex gap-2 text-white font-[500]">
                                {assignBtnLoad.load &&
                                assignBtnLoad.index == index ? (
                                  <button
                                    type="button"
                                    className="w-full text-white bg-primary focus:outline-none rounded-md"
                                    disabled
                                    flex
                                  >
                                    <div className="flex items-center justify-center gap-1">
                                      <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                      <div>assigning ...</div>
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                    id={query._id}
                                    onClick={() => handleActiveQuery(query._id)}
                                    className="p-1  bg-primary rounded-md w-20"
                                  >
                                    Assign
                                  </button>
                                )}

                                {autoBtnLoad.load &&
                                autoBtnLoad.index == index ? (
                                  <button
                                    type="button"
                                    className="w-full text-white bg-blue-300 focus:outline-none rounded-md"
                                    disabled
                                    flex
                                  >
                                    <div className="flex items-center justify-center gap-1">
                                      <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                      <div>forwarding ...</div>
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                    id={query._id}
                                    onClick={() =>{
                                      handleActiveQuery(query._id)
                                      setTransfer(true)
                                    }}
                                    className="p-1 bg-blue-300 rounded-md w-20"
                                  >
                                    Forward
                                  </button>
                                )}
                              </p>
                            )}
                          </span>
                        </p>

                        {
                        checkIfQueryIsEmergency(query) &&

                        <div
                        class={`bg-red-500 w-6 h-6 rounded-full blink-div`}
                      ></div>
                      }
                      </div>
                    );
                  }))
                : (data = queries.map((query, index) => {
                    return (
                      <div
                        key={query._id}
                        className="h-[150px] border border-primary shadow-md my-2 p-2 rounded-md text-primary flex justify-evenly"
                      >
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Name
                          <span className="font-[500]">
                          {query.requester.firstName + " " + query.requester.lastName}
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Service Category
                          <span className="font-[500]">
                            {query.serviceType.name}
                          </span>
                        </p>
                        {
                          adminType == "super" ?

                          <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                            Fee charged
                            <span className="font-[500]">
                              {query.amount}
                            </span>
                          </p>
                          : ""
                        }
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Location
                          <span className="font-[500]">{query.location}</span>
                        </p>
                        {
                          adminType == "super" ?

                          <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                            Phone
                            <span className="font-[500]">
                              {query.requester.phone || "--"}
                            </span>
                          </p>
                          : ""
                        }
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Requested Date
                          <span className="font-[500]">
                            {formatISODate(query.createdAt, true).date}{" "}
                            {formatISODate(query.createdAt, true).time}
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Service Date
                          <span className="font-[500]">
                            {
                              formatISODate(query.date || query.duration.from)
                                .date
                            }{" "}
                            {
                              formatISODate(query.date || query.duration.from)
                                .time
                            }
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Assigned To
                          <span className="font-[500]">
                            {query.company ? query.company.name : "--"}
                          </span>
                        </p>
                        <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                          Status
                          <span
                            className={`font-[800] ${
                              query.status == "placed" &&
                              "inline-block text-center h-6"
                            }`}
                          >
                            {query.status}
                            {query.status == "placed" && (
                              <p className="flex gap-2 text-white font-[500]">
                                {assignBtnLoad.load &&
                                assignBtnLoad.index == index ? (
                                  <button
                                    type="button"
                                    className="w-full text-white bg-primary focus:outline-none rounded-md"
                                    disabled
                                    flex
                                  >
                                    <div className="flex items-center justify-center gap-1">
                                      <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                      <div>assigning ...</div>
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                    id={query._id}
                                    onClick={() => handleActiveQuery(query._id)}
                                    className="p-1  bg-primary rounded-md w-20"
                                  >
                                    Assign
                                  </button>
                                )}

                                {autoBtnLoad.load &&
                                autoBtnLoad.index == index ? (
                                  <button
                                    type="button"
                                    className="w-full text-white bg-blue-300 focus:outline-none rounded-md"
                                    disabled
                                    flex
                                  >
                                    <div className="flex items-center justify-center gap-1">
                                      <div className="h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                      <div>forwarding ...</div>
                                    </div>
                                  </button>
                                ) : (
                                  <button
                                  id={query._id}
                                  onClick={() =>{
                                    handleActiveQuery(query._id)
                                    setTransfer(true)
                                  }}
                                  className="p-1 bg-blue-300 rounded-md w-20"
                                >
                                  Forward
                                </button>
                                )}
                              </p>
                            )}
                          </span>
                        </p>

                      {
                        checkIfQueryIsEmergency(query) &&

                        <div
                        class={`bg-red-500 w-6 h-6 rounded-full blink-div`}
                      ></div>
                      }
                       
                      </div>
                    );
                  }));

              return data.length ? (
                data
              ) : (
                <div className="absolute top-[50%] left-[50%] -translate-x-[50%] text-xl text-primary">
                  {" "}
                  No data found
                </div>
              );
            })()}
        </div>
      </div>

      {transfer || Object.keys(activeQuery).length == 0? (
        ""
      ) : (
        <div className="absolute inset-0 m-auto h-1/2 w-1/2 bg-primary bg-opacity-80 rounded-md flex flex-col">
          <div className="shadow-md shadow-primary py-2">
            <h1 className="text-center">
              Assign Service provider to:{" "}
              <span className="font-bold capitalize">
                {activeQuery.requester.firstName + " " + activeQuery.requester.lastName}
              </span>
            </h1>
            <h2 className="text-center">
              Service Type:{" "}
              <span className="font-bold capitalize">
                {activeQuery.serviceType.name}
              </span>
            </h2>
          </div>
          <div className="py-3 overflow-y-auto flex-1 relative">
            <div>
              <table className="w-full">
                <thead className="border-b-2 border-gray-200">
                  <tr>
                    <th>Name</th>
                    <th>email</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody className="">
                  {serviceproviders.length &&
                    serviceproviders.map((serviceprovider) => {
                      if (
                        serviceprovider.services.some(
                          (service) =>
                            service.category._id ==
                              activeQuery.serviceType._id &&
                            !serviceprovider.booked
                        )
                      ) {
                        return (
                          <tr
                            key={serviceprovider._id}
                            onClick={() =>
                              handleConfirmManualAssignMent(serviceprovider._id)
                            }
                            className="py-3 cursor-pointer hover:bg-white bg-opacity-10"
                          >
                            <td className="text-center">
                              {serviceprovider.firstName + " " + serviceprovider.lastName}
                            </td>
                            <td className="text-center">
                              {serviceprovider.email}
                            </td>
                            <td className="text-center">
                              {serviceprovider.phone}
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>

            {(() => {
              let number = 0;
              serviceproviders.forEach((serviceprovider) => {
                if (
                  serviceprovider.services.some(
                    (service) =>
                      service.category._id === activeQuery.serviceType._id
                  )
                ) {
                  number++;
                }
              });

              // check if service providers were found
              return (
                <div className="absolute bottom-[50%] left-[50%] -translate-x-[50%] text-center">
                  {number ? null : (
                    <p className="block">No Service Providers found</p>
                  )}
                </div>
              );
            })()}
          </div>

          <span
            onClick={removePopUp}
            className="text-5xl text-red-400 hover:text-red-500 cursor-pointer absolute top-2 right-2"
          >
            <CiCircleRemove />
          </span>
        </div>
      )}

      {Object.keys(activeQuery).length == 0 || !transfer? (
        ""
      ) : (
        <div className="absolute inset-0 m-auto h-1/2 w-1/2 bg-primary bg-opacity-80 rounded-md flex flex-col">
          <div className="shadow-md shadow-primary py-2">
            <h1 className="text-center">
              Forward Request
            </h1>
            <h2 className="text-center">
              Service Type:{" "}
              <span className="font-bold capitalize">
                {activeQuery.serviceType.name}
              </span>
            </h2>
          </div>
          <div className="py-3 overflow-y-auto flex-1 relative">
            <div>
              <table className="w-full">
                <thead className="border-b-2 border-gray-200">
                  <tr>
                    <th>Partner Name</th>
                    <th>Phone</th>
                    <th>Admin</th>
                  </tr>
                </thead>
                <tbody className="">
                  {companies.length &&
                    companies.map((company) => {
                      if (
                        company.services.some(
                          (service) =>
                            service._id ==
                              activeQuery.serviceType._id
                        )
                      ) {
                        return (
                          <tr
                            key={company._id}
                            onClick={() =>
                              confirmRequestForward(company)
                            }
                            className="py-3 cursor-pointer hover:bg-white bg-opacity-10"
                          >
                            <td className="text-center">
                              {company.name}
                            </td>
                            <td className="text-center">
                              {company.phone}
                            </td>
                            <td className="text-center">
                              {company.owner.firstName + " " + company.owner.lastName}
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>

            {(() => {
              let number = 0;
              companies.forEach((company) => {
                if (
                  company.services.some(
                    (service) =>
                      service._id === activeQuery.serviceType._id
                  )
                ) {
                  number++;
                }
              });

              // check if companies are found
              return (
                <div className="absolute bottom-[50%] left-[50%] -translate-x-[50%] text-center">
                  {number ? null : (
                    <p className="block">No Service Providers found</p>
                  )}
                </div>
              );
            })()}
          </div>

          <span
            onClick={removePopUp}
            className="text-5xl text-red-400 hover:text-red-500 cursor-pointer absolute top-2 right-2"
          >
            <CiCircleRemove />
          </span>
        </div>
      )}
    </section>
  );
};

function formatISODate(isoString, createdAt) {
  const date = new Date(isoString); 

  // Check if the input ISO string is valid
  if (isNaN(date)) {
    return { date: "Invalid Date", time: "Invalid Time" };
  }

  if(createdAt){
    date.setHours(date.getHours() + 3)
  }
  // Subtract 3 hours from the date
  date.setHours(date.getHours() - 3);

  const optionsDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return { date: formattedDate, time: formattedTime };
}

function checkIfQueryIsEmergency(query) {
  if (query.status !== "placed") {
    return false;
  }

  const createdAt = new Date(query.createdAt);
  createdAt.setHours(createdAt.getHours() + 3);

  const date = query.date || query.duration.from
  const requestedDate = new Date(date);

  const hoursDiff = Math.floor((requestedDate - createdAt) / (1000 * 60 * 60));

  if (hoursDiff > 6) {
    return false;
  }

  return true;
}

export default Queries;
