import { useState } from 'react'
import url from '../baseUrl'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import getAdminDetails from '../utilities/getAdminDetails';

const LogIn = () => {
    const [logInDetails, setLogInDetails] = useState({
        email_id_phone: "",
        password: ""
    })
    const [btnLoad, setBtnLoad] = useState(false)
    const [resetBtnLoad, setResetBtnLoad] = useState(false)
    const [resetPassWordPage, setResetPasswordPage] = useState(false)
    const [resetPasswordDetails, setResetPasswordDetails] = useState({})

    const navigate = useNavigate()

    const handleLogIns = (e)=>{
        const key = e.target.name;
        const value = e.target.value;

        setLogInDetails({
            ...logInDetails,
            [key]: value,
        });
    }

    const handleResetPasswordDetials = (e)=>{
        const key = e.target.name
        const value = e.target.value.trim()

        setResetPasswordDetails({
            ...resetPasswordDetails,
            [key]:value
        })
    }

    const submit = async (e, data)=>{
        if(e){
            e.preventDefault()
        } 

        setBtnLoad(true)
        const response = await fetch(`${url.mainUrl}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data? JSON.stringify(data): JSON.stringify(logInDetails)
        })

        // check if the response is not okey
        if(!response.ok){
            const result = await response.json()
            setBtnLoad(false)
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // milliseconds
            });
            return
        }

        const result = await response.json()

        // reset passoword for admin
        if(result.message){
            setResetPasswordDetails({
                email: logInDetails.email_id_phone,
                otp: logInDetails.password,
                verificationMode: "firstTimeLogIn"
            })
            setResetPasswordPage(true)
            return
        }

        // check if the roles include admin
        if(!result.roles.includes('admin')){
            toast.error("User not found", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // milliseconds
            });
            setBtnLoad(false)
            return
        }

        localStorage.setItem('admin', JSON.stringify(result));
        localStorage.setItem('refreshTime', new Date());
        setBtnLoad(false)

        // get admin details
        const adminDetails = await getAdminDetails(result.access_token)
        if(adminDetails.message){
            localStorage.setItem('adminDetails', JSON.stringify(adminDetails.details));
        }
        navigate('/')

    }

    const resetPassowrd = async (e)=>{
        e.preventDefault()

        //check if details have been inputed
        if(!resetPasswordDetails.newPassword){
            toast.error("Password is required", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // milliseconds
            });

            return
        }

        if(!resetPasswordDetails.confirmPassword){
            toast.error("Confirm password is required", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // milliseconds
            });

            return
        }

        // check if the passwords match
        if(resetPasswordDetails.newPassword !== resetPasswordDetails.confirmPassword){
            toast.error("passwords do not match", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // milliseconds
            });

            return
        }

        setResetBtnLoad(true)

        // submit the details
        const response = await fetch(`${url.mainUrl}/verifyotp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(resetPasswordDetails)
        })

         // check if the response is not okey
         if(!response.ok){
            const result = await response.json()
            setBtnLoad(false)
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000, // milliseconds
            });
            setResetBtnLoad(false)
            return
        }

        const result = await response.json()
        toast.success(result.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // milliseconds
        });

        setLogInDetails({
            ...logInDetails,
            password:  resetPasswordDetails.confirmPassword
        })
        
        setResetBtnLoad(false)

        submit(null, {
            email_id_phone: resetPasswordDetails.email,
            password: resetPasswordDetails.confirmPassword
        })

    }

    return (
        <div className="flex items-center min-h-screen bg-white">
            <div className="container mx-auto relative">
                <div className="max-w-md mx-auto my-10">
                    <div className="text-center">
                        <h1 className="my-3 text-3xl font-semibold text-primary">Sign in</h1>
                    </div>
                    <div className="m-7">
                        <form action="" onSubmit={submit}>
                            <div className="mb-6">
                            <input
                                onChange={handleLogIns}
                                type="email_id_phone"
                                name="email_id_phone"
                                id="email_id_phone"
                                placeholder="Email | Phone | Id"
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                            />
                            </div>
                            <div className="">
                            <input
                                onChange={handleLogIns}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Your Password"
                                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                            />
                            </div>
                            <div className="flex justify-end mb-6">
                                <a href="/forgotpassword" className="text-sm text-gray-400 focus:outline-none focus:text-primary hover:text-primary">Forgot password?</a>
                            </div>
                            <div className="mb-6">
                                {
                                    btnLoad? <button
                                    type="button"
                                    className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                                    disabled
                                >
                                    <div className="flex items-center justify-center">
                                        <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                    </div>
                                </button> :  <button
                                    type="submit"
                                    className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                                >
                                Sign in
                                </button>
                                }
                                
                            </div>
                        </form>
                    </div>
                    
                </div>
                {
                    resetPassWordPage &&

                    <div className='absolute inset-0 m-auto bg-white max-w-md mx-auto my-10'>
                        <div className="text-center">
                            <h1 className="my-3 text-3xl font-semibold text-primary">Kindly reset your password to continue</h1>
                        </div>
                        <div className="m-7">
                            <form action="" onSubmit={resetPassowrd}>
                                <div className="mb-6">
                                <input
                                    onChange={handleResetPasswordDetials}
                                    type="password"
                                    name="newPassword"
                                    id="newPassword"
                                    placeholder="New Password"
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                                />
                                </div>
                                <div className="mb-6">
                                <input
                                    onChange={handleResetPasswordDetials}
                                    type="password"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    placeholder="Confirm Password"
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                                />
                                </div>
                                <div className="mb-6">
                                    {
                                        resetBtnLoad? <button
                                        type="button"
                                        className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                                        disabled
                                    >
                                        <div className="flex items-center justify-center">
                                            <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                                        </div>
                                    </button> :  <button
                                        type="submit"
                                        className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                                    >
                                    Reset Passowrd
                                    </button>
                                    }
                                    
                                </div>
                            </form>
                        </div>
                    </div>

                }
                
            </div>
        </div>
    )
}

export default LogIn