import React, { useEffect, useState } from 'react'
import url from "../../baseUrl";

const HouseBookingModel = () => {

    const [bookings, setBookings] = useState({});

    useEffect(()=>{
        getBookings()
    }, [])

    // get all bookings
    async function getBookings() {
        const admin = JSON.parse(localStorage.getItem("admin"));
        const response = await fetch(
        `${url.mainUrl}/admin/house/book`,
        {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: admin.access_token,
            },
        }
        );

        if (!response.ok) {
        return;
        }

        const result = await response.json();
        setBookings(result);
    }

    console.log(bookings)

    return (
        <section>
            <h1 className='text-primary shadow-md border rounded-md border-primary py-3 text-xl font-bold text-center'>Bookings</h1>
            <div className='flex flex-col gap-2 p-4'>
                {
                    bookings.length && bookings.map(booking => {

                       return <div className='flex text-primary border border-primary rounded-md py-2'>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Name
                                <span className="font-[500]">
                                {booking.requester.firstName + " " + booking.requester.lastName}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Phone
                                <span className="font-[500]">
                                {booking.requester.phone}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Check In Date
                                <span className="font-[500]">
                                {formatISODate(booking.duration.from).date +" "+ formatISODate(booking.duration.from).time}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Check out Date
                                <span className="font-[500]">
                                {formatISODate(booking.duration.to).date + " " + formatISODate(booking.duration.to).date}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                House Type
                                <span className="font-[500]">
                                {booking.house.category.name}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Owners Name
                                <span className="font-[500]">
                                {booking.house.property.owner.firstName}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Owners Contact
                                <span className="font-[500]">
                                {booking.house.property.owner.phone}
                                </span>
                            </p>
                            <p className="flex-1 text-center flex flex-col gap-5 items-center font-bold">
                                Property Location
                                <span className="font-[500]">
                                {booking.house.property.locationCategory && booking.house.property.locationCategory.name}
                                </span>
                            </p>
                       </div>
                       
                    })
                }
                
            </div>
        </section>
    )
}

function formatISODate(isoString, createdAt) {
    const date = new Date(isoString); 
  
    // Check if the input ISO string is valid
    if (isNaN(date)) {
      return { date: "Invalid Date", time: "Invalid Time" };
    }
  
    if(createdAt){
      date.setHours(date.getHours() + 3)
    }
    // Subtract 3 hours from the date
    date.setHours(date.getHours() - 3);
  
    const optionsDate = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
  
    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
  
    return { date: formattedDate, time: formattedTime };
  }

export default HouseBookingModel