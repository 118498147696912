import url from "../baseUrl"

async function getAdminDetails(access_token){
    const response = await fetch(`${url.mainUrl}/details`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': access_token
        }
    })

    if(!response.ok){
        return {
            message: false
        }
    }

    const result = await response.json()
    return {
        message: true,
        details: result
    } 
}

export default getAdminDetails