import { Route, Routes } from "react-router-dom";
import LogIn from "./auth/LogIn";
import ForgotPassword from "./auth/ForgotPassword";
import Home from "./home/Home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <main >
      <div>
        <ToastContainer position="top-right" autoClose={3000} />
      </div>
      <Routes>
        <Route path="/login" element={<LogIn />}/>
        <Route path="/forgotpassword" element={<ForgotPassword />}/>
        <Route path="/*" element={<Home />}/>
      </Routes>
    </main>
  );
}

export default App;
