import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Header from "./header/Header";
import SideBar from "./sideBar/SideBar";

import Dashboard from "./pages/Dashboard";
import Services from "./pages/Services";
import Queries from "./pages/Queries";
import SitInQueries from "./pages/SitInQueries";
import CorporateQueries from "./pages/getCorporateQueries";
import Admins from "./pages/Admins";
import ServiceProviders from "./pages/ServiceProviders";
import Company from "./pages/Company";
import Reports from "./pages/Reports";
import Incidents from "./pages/Incidents";
import Roles from "./pages/Roles";
import Faqs from "./pages/Faqs";
import UploadApps from "./pages/UploadApps";
import logIn from "../utilities/autoLogIn";
import Notification from "./pages/Notification";
import Notifications from "../sockets/Notifications";
import HouseBookingModel from "./pages/HouseBookingModel";
import HouseManagement from "./pages/houses/HouseManagement";

import url from "../baseUrl";

const Home = () => {
  const location = useLocation();
  const [adminDetails, setAdminDetails] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [activePage, setActivePage] = useState(
    () => location.pathname.split("/")[1] || "dashboard"
  );
  const [adminType, setAdminType] = useState("");
  const [inititaliazeFetchQueries, setInitializeFetchQueries] = useState(false)
  const navigate = useNavigate();
  // logIn() //autologin

  const checkLoginStatus = () => {
    const logInDetails = localStorage.getItem("admin");
    const adminDetails = localStorage.getItem("adminDetails");
    if (!logInDetails || !adminDetails) {
      navigate("/login", { replace: true });
      localStorage.clear();
      return;
    }

    setAdminType(JSON.parse(logInDetails).adminType);
    setAdminDetails(JSON.parse(adminDetails));
    getNotifications();
  };

  const checkAutoLogout = async () => {
    let logoutTimeout;

    const logedInTime = new Date(localStorage.getItem("refreshTime"));
    if (!logedInTime) {
      navigate("/login", { replace: true });
      localStorage.clear();
      return;
    }

    const now = new Date();
    const timeDiff = now - logedInTime;
    const standardTime = 2400000 - timeDiff;
    if (standardTime > 0) {
      // Set the timeout for automatic logout
      setTimeout(() => {
        refreshToken();
      }, standardTime);
    } else {
      // If the timeout has already passed, refresh immediately
      refreshToken();
    }
  };

  async function refreshToken() {
    const admin = JSON.parse(localStorage.getItem("admin"));
    if (!admin) {
      navigate("/login", { replace: true });
      localStorage.clear();
      return;
    }

    const response = await fetch(`${url.mainUrl}/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: admin.refresh_token,
      }),
    });

    if (!response.ok) {
      const refreshTime = new Date(localStorage.getItem("refreshTime"));
      const now = new Date();

      // prevent the browser from logging out incase the refreshToken function ran twice
      if (now - refreshTime > 3000000) {
        navigate("/login", { replace: true });
        localStorage.clear();
        window.location.reload();
      }
      return;
    }

    // Update refresh token in local storage
    const result = await response.json();
    admin.refresh_token = result.refresh_token;
    admin.access_token = result.access_token;

    // Store the updated admin object in local storage
    localStorage.setItem("admin", JSON.stringify(admin));
    localStorage.setItem("refreshTime", new Date());

    setTimeout(() => {
      refreshToken();
    }, 2400000);
  }

  useEffect(() => {
    checkLoginStatus();
    checkAutoLogout();
  }, []);

  const handleActivePage = (page) => {
    console.log(page);
    setActivePage(page);
    navigate(`./${page}`);
  };

  async function getNotifications() {
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/notification`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setUnreadNotifications(() => {
      return result.reduce((sum, notification) => {
        if (!notification.read) {
          return sum + 1;
        }

        return sum;
      }, 0);
    });

    setNotifications(result);
  }

  return (
    <section className="flex h-screen w-screen">
      {adminType && (
        <>
          <div className="h-full w-56">
            {/* sidebar */}
            <SideBar active={activePage} handleActivePage={handleActivePage} />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="w-full">
              {/* Header */}
              <Header
                unreadNotifications={unreadNotifications}
                active={activePage}
                handleActivePage={handleActivePage}
              />
            </div>
            <div className="flex-1 border overflow-y-auto">
              {/* main content */}
              <Routes>
                {adminDetails.Permissions.dashboard && <Route path={"/"} element={<Dashboard />} /> }
                {adminDetails.Permissions.dashboard && <Route path={"/dashboard"} element={<Dashboard />} />}
                {adminDetails.Permissions.queries && <Route path={"/queries"} element={<Queries inititaliazeFetchQueries={inititaliazeFetchQueries} setInitializeFetchQueries={setInitializeFetchQueries}/>} /> }
                {adminDetails.Permissions.providers && <Route
                  path={"/serviceproviders"}
                  element={<ServiceProviders />}
                />}
                {adminDetails.Permissions.company && <Route path={"/company"} element={<Company />} /> }
                {adminDetails.Permissions.reports && <Route path={"/reports"} element={<Reports />} /> }

             
                {adminDetails.Permissions.sitins && <Route path={"/sitins"} element={<SitInQueries />} /> }
                {adminDetails.Permissions.corporates && <Route path={"/corporates"} element={<CorporateQueries />} /> }
                {adminDetails.Permissions.admins && <Route path={"/admins"} element={<Admins />} /> }
                {adminDetails.Permissions.services && <Route path={"/services"} element={<Services />} /> }
                {adminDetails.Permissions.incidentReports && <Route path={"/incidents"} element={<Incidents />} /> }
                {adminDetails.Permissions.roles && <Route path={"/roles"} element={<Roles />} /> }
                {adminDetails.Permissions.faqs && <Route path={"/faqs"} element={<Faqs />} /> }
                <Route path={"/upload"} element={<UploadApps />} /> 
                <Route path={"/houses/*"} element={<HouseManagement />} /> 
                <Route path={"/housebookings"} element={<HouseBookingModel />} /> 
                <Route
                  path={"/notifications"}
                  element={
                    <Notification
                      notifications={notifications}
                      handleActivePage={handleActivePage}
                      getNotifications={getNotifications}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
          <Notifications getNotifications={getNotifications}  setInitializeFetchQueries={setInitializeFetchQueries}/>
        </>
      )}
    </section>
  );
};

export default Home;
