import React, { useState, useEffect } from 'react'
import url from '../../../baseUrl'
import { CiCircleRemove } from "react-icons/ci";
import { toast } from 'react-toastify';

const HouseLocations = () => {
    const [locationName, setLocationName] = useState(null)
    const [categories, setCategories] = useState([])
    const [houseType, setHouseType] = useState(null)
    const [activeCategory, setActiveCategory] = useState()
    const [filteredCategories, setFilteredCategories] = useState([])
    const [prices, setPrices] = useState([])

    const getCategories = async ()=>{
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            const response = await fetch(`${url.mainUrl}/admin/house/category/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: admin.access_token,
                }
            })

            if(!response.ok){
                const result = await response.json()
                console.log(result)
                return
            }

            const data = await response.json()
            setCategories(data)
        }
        catch(error){
            console.log(error)
        }
    }

    useEffect(()=>{
        getCategories()
    }, [])

    const handleFilteredCategories = (_id) => {
        setFilteredCategories(()=>{
            const selectedCategories = [...filteredCategories]
            const selectedCategory = categories.filter((category) => category._id == _id )
            selectedCategories.push(selectedCategory[0])

            return selectedCategories
        })
    }

    const removeItemFromFileter = (_id) => {
        setFilteredCategories(()=>{
            const selectedCategories = [...filteredCategories]
            const filteted = selectedCategories.filter(categ => categ._id !== _id)
            return filteted
        })

        setPrices(()=> {
            if(prices.some(price => price._id == _id)){
                const pricesCategories = prices.filter(price => price._id != _id);
                return pricesCategories
            }

            return prices
        })
    }

    console.log(prices)
    const handlePrices = (key, value) => {
        setPrices(()=>{
            let currentPrices = [...prices]
            for(let i =0; i< currentPrices.length; i++){
                const price = currentPrices[i]
                if(price._id == key){
                    currentPrices[i].price = value
                    return currentPrices
                }

            }

            currentPrices.push({
                _id: key,
                price: value
            })

            return currentPrices
           
        })
    }

    async function submitDetails(e){
        e.preventDefault()
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            const response = await fetch(`${url.mainUrl}/admin/house/location`, {
                method: "POST",
                headers: {
                    Authorization: admin.access_token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: locationName,
                    categories: prices
                })
            })

            if(!response.ok){
                const result = await response.json()
                toast.error(result.error, {
                    position: toast.POSITION.TOP_CENTER
                })

                return
            }

            const result = await response.json()
            toast.success(result.message, {
                position: toast.POSITION.TOP_CENTER
            })  

        }
        catch(error){
            console.log(error)
        }
    }


    return (
        <section className='flex flex-col items-center p-4 gap-2'>
            <div className='w-3/4 flex flex-col gap-2'>
                <h1 className='text-primary border border-primary p-2 text-center rounded-md font-bold shadow-md'>Add House Location</h1>
                <form action="" onSubmit={submitDetails} className='w-full flex flex-col gap-2'>
                    <input type="text" onChange={(e)=> setLocationName(e.target.value)} placeholder='Enter location name' className="text-primary w-full px-4 py-2 border border-primary rounded-md" />
                    <div className='flex items-center justify-center flex-wrap gap-2 rounded-md'>
                        {
                            !filteredCategories? "" :
                            filteredCategories.map(category => {
                                return <div className='flex flex-col text-white bg-primary rounded-md p-2 gap-1 w-max'>
                                                    <span onClick={()=> removeItemFromFileter(category._id)} className='text-2xl text-red-800 font-bold cursor-pointer block mx-auto'>
                                                        <CiCircleRemove />
                                                    </span>
                                                <p className='flex gap-2 items-center justify-between'>
                                                    <span className='capitalize'>{category.name}</span>
                                                    <span className='capitalize font-bold underline'>{category.type}</span>
                                                </p>
                                            
                                        </div>
                            })
                        }
                    </div>
                    <div className='w-full flex items-center justify-evenly gap-2'>
                        {categories.length  && 
                            <select onChange={(e) => setHouseType(e.target.value)} className='text-primary px-4 py-2 border border-primary rounded-md w-full'>
                                <option selected disabled>Choose House type</option>
                                <option value="bnb">Bnb</option>
                                <option value="rental">Rental</option>
                            </select>
                        }
                        {
                            houseType && 
                            <select name="" id="" onClick={(e)=> setActiveCategory(e.target.value)} className="text-primary px-4 py-2 border border-primary rounded-md w-full">
                                <option selected disabled>Choose House Categories</option>
                                {
                                    categories.length && categories.map(category => {
                                        if(category.type == houseType && !filteredCategories.some(cat => cat._id == category._id)){
                                            return <option value={category._id}>{category.name}</option>
                                        }
                                    })
                                }
                            </select>
                        }
                        {categories.length && <button onClick={() => handleFilteredCategories(activeCategory)} type='button' className='block mx-auto bg-primary w-32 text-white py-2 rounded-md'>Add</button>}
                    </div>
                    {
                        !filteredCategories.length? "" :
                        filteredCategories.map(filteredCategory => {
                            return <div className='flex gap-2 items-center'>
                                <label className='text-primary font-bold flex-1' htmlFor={filteredCategory._id}>{`Price for ${filteredCategory.name} ${filteredCategory.type} at ${locationName}`}</label>
                                <input type="number" onChange={(e)=> handlePrices(filteredCategory._id, e.target.value)} className="text-primary flex-1 w-full px-4 py-2 border border-primary rounded-md" id={filteredCategory._id} placeholder='price' />
                            </div>
                        })
                    }

                    {!filteredCategories.length? "" : <button type='submit' className='block mx-auto bg-primary w-32 text-white py-2 rounded-md'>submit</button> }
                </form>
            </div>
        </section>
    )
}

export default HouseLocations