// get all services
import url from "../baseUrl"

async function getServiceProviders( company ){
    const admin = JSON.parse(localStorage.getItem('admin'))
    const response = await fetch(`${url.mainUrl}/admin/serviceprovider${company? '?company=' + company : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': admin.access_token
      }
    })

    if(!response.ok){
      return {
        message: false
      }
    }

    const result = await response.json()
    return {
        message: true,
        serviceProviders: result.servicePrividers
    }
}

export default getServiceProviders