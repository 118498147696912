import React, { useEffect, useState } from 'react'
import url from '../../../baseUrl'
import { toast } from 'react-toastify'

const HouseCategories = () => {
    const [categories, setCategories] = useState([])
    const [categoryDetails, setCategoryDetails] = useState({})

    const getCategories = async ()=>{
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            const response = await fetch(`${url.mainUrl}/admin/house/category/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: admin.access_token,
                }
            })

            if(!response.ok){
                const result = await response.json()
                console.log(result)
                return
            }

            const data = await response.json()
            setCategories(data)
        }
        catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        getCategories()
    }, [])

    const handleCategoryDetails = (key, value) => {
        setCategoryDetails(()=>{
            const data = { ...categoryDetails}
            data[key] = value

            return data
        })
    }

    async function submitDetails(e){
            e.preventDefault()
            const admin = JSON.parse(localStorage.getItem("admin"));
            try{
                const response = await fetch(`${url.mainUrl}/admin/house/category/`, {
                    method: "POST",
                    headers: {
                        Authorization: admin.access_token,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(categoryDetails)
                })

                if(!response.ok){
                    const result = await response.json()
                    toast.error(result.error, {
                        position: toast.POSITION.TOP_CENTER
                    })

                    return
                }

                const result = await response.json()
                toast.success(result.message, {
                    position: toast.POSITION.TOP_CENTER
                })  

                getCategories()
            }
            catch(error){
                console.log(error)
            }
    }

    return (
        <div className='h-full flex flex-col items-center justify-center gap-5 py-3'>

            {/* Display House Categories */}
            <div className='w-1/2 border border-primary rounded-md overflow-hidden'>
                <h1 className='text-primary border border-primary rounded-md py-2 text-center'>Available House Categories</h1>
                <div className='p-2'>
                    <table className='w-full text-center text-primary p-2 border border-primary rounded-md'>
                        <thead>
                            <tr className='font-bold text-xl border border-primary rounded-md'>
                                <td>Name</td>
                                <td>Type</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                categories.length && categories.map(category => {
                                    return   <tr>
                                                <td className='capitalize'>{category.name}</td>
                                                <td>{category.type}</td>
                                            </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            
            </div>

            {/* Add House category */}
            <div className='w-1/2 border border-primary overflow-hidden rounded-md'>
                <h1 className='text-primary border border-primary rounded-md py-2 text-center'>Add House Category</h1>
                <form action="" onSubmit={submitDetails} className='p-2 flex flex-col gap-2'>
                    <input  onChange={(e)=> handleCategoryDetails("name", e.target.value)} type="text" name='name' className="text-primary px-4 py-2 border border-primary rounded-md" placeholder='category e.g studio, one-bedroom' />
                    <select onChange={(e)=> handleCategoryDetails("type", e.target.value)} name="type" id="" className="text-primary px-4 py-2 border border-primary rounded-md">
                        <option value="" disabled selected>choose type</option>
                        <option value="bnb">Bnb</option>
                        <option value="rental">Rental</option>
                    </select>
                    <button type='submit' className='block mx-auto bg-primary w-32 text-white py-2 rounded-md'>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default HouseCategories

