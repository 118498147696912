import React, { useEffect, useRef, useState } from "react";
import url from "../../baseUrl";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";

const Services = () => {
  const [btnLoad, setBtnLoad] = useState(false);
  const [updateBtnLoad, setUpdatebtnLoad] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [service, setService] = useState({});
  const [fieldsToUpdate, setFieldsToUpdate] = useState({});
  const [activeService, setActiveService] = useState({});
  const [activeServiceType, setActiveServiceType] = useState("");
  const [fetchedServices, setFetchedServices] = useState(false);
  const priceRef = useRef(null);
  const serviceFeeRef = useRef(null);
  const hourlyChargeRef = useRef(null);
  const extendFeeRef = useRef(null);

  const handleActiveService = (_id) => {
    const filteredServices = serviceCategories.filter(
      (singleService) => singleService._id == _id
    );

    setActiveService(filteredServices[0]);
    setFieldsToUpdate({
      name: filteredServices[0].name,
      price: filteredServices[0].price,
      serviceFee: filteredServices[0].serviceFee,
      hourlyCharge: filteredServices[0].hourlyCharge,
      extendFee: filteredServices[0].extendFee
    });
  };

  const handleActiveServiceType = (e) => {
    const value = e.target.value;
    const data = service;
    setActiveServiceType(value);
    if (value == "standardcharge") {
      if (data.serviceFee) {
        delete data.serviceFee;
        serviceFeeRef.current.value = "";
      }

      if (data.hourlyCharge) {
        delete data.hourlyCharge;
        hourlyChargeRef.current.value = "";
      }

      setService(data);
    }

    if (value == "6hourscharge") {
      if (data.serviceFee) {
        delete data.serviceFee;
        serviceFeeRef.current.value = "";
      }

      setService(data);
    }

    if (value == "servicefeeonly") {
      if (data.price) {
        delete data.price;
        priceRef.current.value = "";
      }

      if (data.hourlyCharge) {
        delete data.hourlyCharge;
        hourlyChargeRef.current.vlaue = "";
      }
      extendFeeRef.current.value = "";

      setService(data);
    }

    if(value == "sitin" || value == "corporate"){
      hourlyChargeRef.current.vlaue = "";
      priceRef.current.value = "";
      extendFeeRef.current.value = ""
    }

    handleAddService(e);
  };

  const handleAddService = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setService({
      ...service,
      [key]: value,
    });
  };

  const handleFieldsToUpdate = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setFieldsToUpdate({
      ...fieldsToUpdate,
      [key]: value,
    });
  };

  useEffect(() => {
    getAllServices();
  }, []);

  // get all services
  async function getAllServices() {
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/admin/category`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setServiceCategories(result.categories);
    setFetchedServices(true);
  }

  // submit service
  const submitService = async (e) => {
    e.preventDefault();

    setBtnLoad(true);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/admin/category`, {
      method: "POST",
      body: JSON.stringify(service),
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      setBtnLoad(false);
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
    setBtnLoad(false);
    getAllServices();
  };

  // const update service
  const updateService = async (e) => {
    e.preventDefault();
    setUpdatebtnLoad(true);

    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(
      `${url.mainUrl}/admin/category/${activeService._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
        body: JSON.stringify(fieldsToUpdate),
      }
    );

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      setUpdatebtnLoad(false);
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
    setActiveService({});
    setUpdatebtnLoad(false);
    getAllServices();
  };

  // const delete service
  const deleteService = async (_id) => {
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/admin/category/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
    getAllServices();
  };

  const handleConfirm = (_id) => {
    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>Are you sure you want to delete this service?</p>
        <div className="flex justify-evenly mt-3">
          <button
            onClick={() => {
              deleteService(_id);
              closeToast();
            }}
            className="py-1 px-3 bg-danger rounded-md text-white"
          >
            Accept
          </button>
          <button className="py-1 px-3 bg-green-600 rounded-md text-white">
            Decline
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  };

  return (
    <section className="px-12 py-4">
      <div className="flex justify-between py-2 ">
        {/* add service */}
        <div className="w-[40%] border border-primary rounded-md">
          <h1 className="shadow-md shadow-primary py-4 text-center text-primary fonr-bold text-xl mb-5">
            Add Service
          </h1>
          <form
            action=""
            onSubmit={submitService}
            className="flex flex-col gap-2 px-3 py-3"
          >
            <input
              onChange={handleAddService}
              type="text"
              name="name"
              placeholder="Service Name"
              className="w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
            />
            <select
              name="type"
              id="type"
              onChange={handleActiveServiceType}
              className="flex-1 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
            >
              <option selected disabled>
                Choose Service Type
              </option>
              <option value={"standardcharge"}>Standard Charge</option>
              <option value={"6hourscharge"}>
                Standard Charge For 6 hours
              </option>
              <option value={"servicefeeonly"}>Service Fee Only</option>
              <option value={"sitin"}>sit in</option>
              <option value={"corporate"}>Corporate</option>
            </select>
            <input
              onChange={handleAddService}
              ref={priceRef}
              type="Number"
              name="price"
              placeholder="Price"
              className={`${
                activeServiceType
                  ? activeServiceType == "servicefeeonly" ||
                    activeServiceType == "sitin" || 
                    activeServiceType == "corporate"
                    ? "hidden"
                    : "block"
                  : "hidden"
              } w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
            />
            <input
              onChange={handleAddService}
              ref={serviceFeeRef}
              type="Number"
              name="serviceFee"
              placeholder="Service Fee"
              className={`${
                activeServiceType != "servicefeeonly" && "hidden"
              } w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
            />
            <input
              onChange={handleAddService}
              ref={hourlyChargeRef}
              type="Number"
              name="hourlyCharge"
              placeholder="Hourly Charge"
              className={`${
                activeServiceType !== "6hourscharge" && "hidden"
              } w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
            />
             <input
              onChange={handleAddService}
              ref={extendFeeRef}
              type="Number"
              name="extendFee"
              placeholder="Extend Fee"
              className={`${
                ["servicefeeonly", "sitin", "corporate"].includes(activeServiceType) && "hidden"
              } w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
            />

            {btnLoad ? (
              <button
                type="button"
                className="w-1/2 block mx-auto px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                disabled
              >
                <div className="flex items-center justify-center">
                  <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="w-1/2 block mx-auto px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
              >
                submit
              </button>
            )}
          </form>
        </div>

        {/* get all services or delete */}
        <div className="w-[40%] border border-primary rounded-md">
          <h1 className="shadow-md shadow-primary py-4 text-center text-primary fonr-bold text-xl">
            All Services
          </h1>
          <div className="px-3 py-2 flex flex-col gap-2 overflow-auto min-h-[200px] max-h-[300px] relative">
            {!serviceCategories.length ? (
              <div className="absolute left-[50%] top-[50%] -translate-x-[50%]">
                {fetchedServices ? (
                  <div className="text-gray-500 text-xl">
                    {" "}
                    No Services found
                  </div>
                ) : (
                  <PropagateLoader color="#B7926B" loading={true} size={10} />
                )}
              </div>
            ) : (
              serviceCategories.map((category) => {
                return (
                  <div
                    key={category._id}
                    className="flex px-2 justify-between items-center border border-primary py-2 rounded-md"
                  >
                    <span className="w-[30%]">{category.name}</span>
                    <div>
                      <button
                        type="button"
                        id={category._id}
                        onClick={() => handleActiveService(category._id)}
                        className="py-1 mx-2 px-3 bg-green-300 rounded-md hover:bg-success focus:bg-success"
                      >
                        View
                      </button>
                      <button
                        type="button"
                        id={category._id}
                        onClick={() => handleConfirm(category._id)}
                        className="py-1 px-3 bg-red-300 rounded-md hover:bg-danger focus:bg-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {!Object.keys(activeService).length ? (
        ""
      ) : (
        <div className=" border border-primary rounded-md">
          {/* update service */}
          <h1 className="shadow-md shadow-primary py-4 text-center text-primary fonr-bold text-xl mb-5">
            Update Service
          </h1>
          <form
            action=""
            onSubmit={updateService}
            className="flex flex-col gap-2 px-3 py-3"
          >
            <div className="flex gap-4">
              <div className="flex-1">
                <label htmlFor="name">Name</label>
                <input
                  onChange={handleFieldsToUpdate}
                  placeholder={activeService.name}
                  type="text"
                  name="name"
                  className={` w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
                />
              </div>
              <div className={`${
                    activeService.price || "hidden"
                  } flex-1`}>
                <label htmlFor="price">Price</label>
                <input
                  onChange={handleFieldsToUpdate}
                  placeholder={activeService.price}
                  type="Number"
                  name="price"
                  className={`w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
                />
              </div>
              <div className={`${
                    activeService.serviceFee || "hidden"
                  } flex-1`}>
                <label htmlFor="Number">Service Fee</label>
                <input
                  onChange={handleFieldsToUpdate}
                  placeholder={activeService.serviceFee}
                  type="Number"
                  name="serviceFee"
                  className={`${
                    activeService.serviceFee || "hidden"
                  } w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
                />
              </div>
              <div className={`${
                    activeService.hourlyCharge || "hidden"
                  } flex-1`}>
                <label htmlFor="hourlyCharge">Hourly Charge</label>
                <input
                  onChange={handleFieldsToUpdate}
                  placeholder={activeService.hourlyCharge}
                  type="Number"
                  name="hourlyCharge"
                  className={`${
                    activeService.hourlyCharge || "hidden"
                  } w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
                />
              </div>
              <div className={`${
                    ['servicefeeonly', 'sitin', 'corporate'].includes(activeService.type) && "hidden"
                  } flex-1`}>
                <label htmlFor="hourlyCharge">Extend Fee</label>
                <input
                  onChange={handleFieldsToUpdate}
                  placeholder={activeService.extendFee || 0}
                  type="Number"
                  name="extendFee"
                  className={`w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
                />
              </div>
              <div className="flex-1">
                  <label htmlFor="">Service Type</label>
                  <input
                  type="Number"
                  name="hourlyCharge"
                  placeholder={returnServiceTypeName(activeService.type)}
                  disabled
                  className={`w-full px-3 py-2 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700`}
                />
              </div>
            </div>

            {updateBtnLoad ? (
              <button
                type="button"
                className="w-1/2 block mx-auto px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                disabled
              >
                <div className="flex items-center justify-center">
                  <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                </div>
              </button>
            ) : (
              <button
                type="submit"
                className="w-1/2 block mx-auto px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
              >
                Update
              </button>
            )}
          </form>
        </div>
      )}
    </section>
  );
};

function returnServiceTypeName(serviceType){

    if(serviceType == 'sitin'){
        return "Sit In"
    }

    if(serviceType == 'corporate'){
      return "Corporate"
    }

    if(serviceType == 'servicefeeonly'){
        return "Service Fee Only"
    }

    if(serviceType == 'standardcharge'){
        return "Standard Charge"
    }
    
    if(serviceType == '6hourscharge'){
        return "6 Hours Charge"
    }
}
export default Services;
