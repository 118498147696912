import React, { useEffect, useState } from 'react'
import url from '../../../baseUrl'
import { usePlacesWidget } from 'react-google-autocomplete';
import { toast } from 'react-toastify';
import House from './House';
import { CiCircleRemove } from "react-icons/ci";


const Properties = () => {
    const [houseLocations, setHouseLocations] = useState([]);
    const [properties, setProperties] = useState([]);
    const [propertyDetails, setPropertyDetails] = useState({});
    const [propertySection, setPropertySection] = useState(false)
    const [propertyId, setPropertyId] = useState("")

    const handleSelectedLocation = (place) =>{
        const location = {
            longitude: place.geometry.location.lat(),
            latitude: place.geometry.location.lng(),
            name: ref.current.value
        }
        const existingDetails = JSON.parse(localStorage.getItem("propertyDetails") || "{}");

        existingDetails['location'] = location
        setPropertyDetails(existingDetails);
    }

    const { ref } = usePlacesWidget({
      apiKey: 'AIzaSyA4aJrM5yqj1kM8n_5WA46xYgqJk4KZZfk',
      options: {
        componentRestrictions: { country: 'ke' }, 
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"]
      },
      onPlaceSelected: (place) => {
        handleSelectedLocation(place)
      },
    });


    useEffect(()=>{
        async function getHouseLocations(){
            try{
                const admin = JSON.parse(localStorage.getItem("admin"));
                const response = await fetch(`${url.mainUrl}/admin/house/location`, {
                    method: "GET",
                    headers: {
                        Authorization: admin.access_token,
                        "Content-Type": "application/json"
                    }
                })

                if(!response.ok){
                    const result = await response.json()
                    console.log(result)
                    return
                }

                const result = await response.json()
                setHouseLocations(result)

            }
            catch(error){
                console.log(error)
            }
        }

        getHouseLocations()
        getProperties()
    }, [])


    console.log(properties)

    function handlePropertyDetails(key, value, user){
        setPropertyDetails(()=>{
            const existingDetails = {...propertyDetails}
            if(user){
                existingDetails[user] = {...existingDetails[user] || {}}
                existingDetails[user][key] = value
            }
            else{
                existingDetails[key] = value
            }

            localStorage.setItem('propertyDetails', JSON.stringify(existingDetails))
            return existingDetails
        })

    }

    async function getProperties(propertyId){
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            const response = await fetch(`${url.mainUrl}/admin/property/`, {
                method: "GET",
                headers: {
                    Authorization: admin.access_token,
                    "Content-Type": "application/json"
                }
            })

            if(!response.ok){
                const result = await response.json()
                console.log(result)
                return
            }

            const result = await response.json()
            setProperties(result)
            setPropertySection(false)
            if(propertyId){
                setPropertyId(propertyId)
            }

        }
        catch(error){
            console.log(error)
        }
    }

    async function submitDetails(){
        if(!propertyDetails.location){
            toast.error("Please select location once again", {
                position: toast.POSITION.TOP_CENTER
            })
            return
        }
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            toast.info("Adding property...", {
                position: toast.POSITION.TOP_CENTER
            })  
            const response = await fetch(`${url.mainUrl}/admin/property/`, {
                method: "POST",
                headers: {
                    Authorization: admin.access_token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(propertyDetails)
            })

            if(!response.ok){
                const result = await response.json()
                toast.error(result.error, {
                    position: toast.POSITION.TOP_CENTER
                })

                return
            }

            const result = await response.json()
            toast.success(result.message, {
                position: toast.POSITION.TOP_CENTER
            })  

            getProperties(result.property._id)
        }
        catch(error){
            console.log(error)
        }

    }

    return (
        <section className='flex flex-col items-center py-2'>
            {
                propertyId? <House propertyId={propertyId} setPropertyId={setPropertyId} properties={properties}/> : ""
            }
           
                
                {/* View Properties */}
            <div className={`w-full p-2 ${propertyId && "hidden"}  ${propertySection && "hidden"}`}>
                <button type='button' onClick={() => setPropertySection(true)} className='block mx-auto bg-primary w-32 text-white py-2 rounded-md my-2'>Add Property</button>

                <h1 className='text-primary border border-primary rounded-md p-2 font-bold text-xl text-center'>All Properties</h1>
                <div className='flex justify-evenly gap-4 flex-wrap p-2'>
                    {
                        !properties? "" : 
                        properties.map(property => {
                            return <div onClick={()=> setPropertyId(property._id)} className='border cursor-pointer border-primary w-64 min-h-64 text-primary flex flex-col justify-between p-2 rounded-md'>
                                        <p className='font-bold'>Name: <span className='font-[400]'>{property.name}</span></p>
                                        <p className='font-bold'>Location: <span className='font-[400]'>{property.locationCategory.name}</span></p>
                                        <p className='font-bold'>Exact Location: <span className='font-[400]'>{property.location.name}</span></p>
                                        <div>
                                            <h2 className='font-bold underline'>Owner details</h2>
                                            <p className='font-bold px-2'>Name: <span className='font-[400]'>{property.owner.firstName + " " + property.owner.lastName}</span></p>
                                            <p className='font-bold px-2'>Contact: <span className='font-[400]'>{property.owner.phone}</span></p>
                                        </div>
                                        <div>
                                            <h2 className='font-bold underline'>Contact Person details</h2>
                                            <p className='font-bold px-2'>Name: <span className='font-[400]'>{property.contactPerson.firstName + " " + property.contactPerson.lastName}</span></p>
                                            <p className='font-bold px-2'>Contact: <span className='font-[400]'>{property.contactPerson.phone}</span></p>
                                        </div>
                                    </div>
                        })
                    
                    }
                
                </div>
            </div>


                    {/* add property */}
            <div className={`w-1/2 ${propertyId && "hidden"} ${!propertySection && "hidden"}`}>
                <span onClick={() => setPropertySection(false)} className=' block w-max mx-auto text-red-700 text-5xl cursor-pointer'>
                        <CiCircleRemove />
                </span>
                <h1 className='text-primary text-center py-2 border border-primary rounded-md font-bold'>Add Property To location</h1>
                <form action="" onSubmit={(e) => e.preventDefault()} className='py-2 flex flex-col gap-2'>
                    <input type="text" name='name' className='text-primary px-4 py-2 border border-primary rounded-md w-full' onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value)} placeholder='Enter property name' />
                    {
                        !houseLocations.length? "" :
                        <select name="locationCategory" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value)} className='text-primary px-4 py-2 border border-primary rounded-md w-full' id="">
                            <option selected disabled>Select Property location</option>
                            {
                                houseLocations.map(location => {
                                    return <option value={location._id}>{location.name}</option>
                                })
                            }
                        </select>
                    }
                    <div className='border border-primary p-2 rounded-md flex flex-col gap-1'>
                        <p className='text-sm font-bold text-primary'>Owner Details</p>
                        <input type="text" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value, "owner")} name="firstName" className='text-primary px-4 py-2 border border-primary rounded-md w-full' placeholder="Enter owners firstName" />
                        <input type="text" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value, "owner")} name="lastName" className='text-primary px-4 py-2 border border-primary rounded-md w-full' placeholder='Enter owners last name' id="" />
                        <input type="text" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value, "owner")} name="phone" className='text-primary px-4 py-2 border border-primary rounded-md w-full' placeholder='Enter owners phone number' id="" />
                    </div>
                    <div className='border border-primary p-2 rounded-md flex flex-col gap-1'>
                        <p className='text-sm font-bold text-primary'>Contact Person</p>
                        <input type="text" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value, "contactPerson")} name="firstName" className='text-primary px-4 py-2 border border-primary rounded-md w-full' placeholder="Enter contact person's firstName" />
                        <input type="text" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value, "contactPerson")} name="lastName" className='text-primary px-4 py-2 border border-primary rounded-md w-full' placeholder="Enter contact person's last name" id="" />
                        <input type="text" onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value, "contactPerson")} name="phone" className='text-primary px-4 py-2 border border-primary rounded-md w-full' placeholder="Enter contact person's phone number" id="" />
                    </div>
                    <input type="number" name='paymentsPhoneNumber' placeholder='Payments phone number' onChange={(e)=> handlePropertyDetails(e.target.name, e.target.value)} className='text-primary px-4 py-2 border border-primary rounded-md w-full'/>
                    <input
                        ref={ref}
                        placeholder="Enter a location"
                        type="text"
                        className='text-primary px-4 py-2 border border-primary rounded-md w-full'
                    />
                    <button type='button' onClick={() => submitDetails()} className='block mx-auto bg-primary w-32 text-white py-2 rounded-md'>submit</button>
                </form>
            </div>
           
        </section>
    )
}

export default Properties