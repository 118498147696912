import React from 'react'
import url from '../../baseUrl'

const Notification = ({notifications, handleActivePage, getNotifications}) => {

    const routeToSpecificDirectory = (type, _id)=> {
        if(type == "query"){
            handleActivePage('queries')
            markNotificationAsRead(_id)
        }
    }

    async function markNotificationAsRead(_id) {
        const admin = JSON.parse(localStorage.getItem('admin'))
        const response = await fetch(`${url.mainUrl}/notification/${_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        })

        const result = await response.json()
        console.log(result)
        await getNotifications()
        return
    }

    return (
        <section className='h-full p-4'>
            <div className='border border-primary rounded-md h-full flex flex-col'>
                <h1 className='shadow-md shadow-primary font-bold text-center text-xl text-primary py-2'>All Notifications</h1>
                <div className='flex-1 p-2 flex flex-col gap-2'>
                    {
                        notifications.length && 
                        notifications.map((notification)=> {
                            return  <div onClick={()=> routeToSpecificDirectory(notification.type || "query", notification._id)} className={`border border-primary rounded-md text-primary ${notification.read? "opacity-50 font-normal" : "font-bold"} cursor-pointer`}>
                                <h1 className='shadow-md shadow-primary py-2 px-3 flex gap-2'>
                                    <span>{formatISODate(notification.createdAt).date}</span>
                                    <span>{formatISODate(notification.createdAt).time}</span>
                                </h1>
                                <p className='p-4'>{notification.message}</p>

                            </div>
                        })
                       
                    }
                   
                </div>
            </div>
        </section>
    )
}

function formatISODate(isoString) {
    const date = new Date(isoString);
  
    // Check if the input ISO string is valid
    if (isNaN(date)) {
      return { date: "Invalid Date", time: "Invalid Time" };
    }
  
    // Subtract 3 hours from the date
    date.setHours(date.getHours());
  
    const optionsDate = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
  
    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
  
    return { date: formattedDate, time: formattedTime };
  }

export default Notification