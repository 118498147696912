import React, { useEffect, useState, useRef } from "react";
import url from "../../baseUrl";
import { toast } from "react-toastify";
import { PropagateLoader } from "react-spinners";
import { CiCircleRemove } from "react-icons/ci";

const Admins = () => {
  const [admins, setAdmins] = useState([]);
  const [fetchedAdmins, setFetchedAdmins] = useState(false);
  const [adminDetails, setAdminDetails] = useState({
    permissions: {
      admins: false,
      dashboard: false,
      queries: false,
      providers: false,
      sitins: false,
      corporates: false,
      company: false,
      incidentReports: false,
      roles: false,
      services: false,
      faqs: false,
      reports: false,
    },
  });
  const [adminPermissions, setAdminPermissions] = useState({
    admins: false,
    dashboard: false,
    queries: false,
    providers: false,
    sitins: false,
    corporates: false,
    company: false,
    incidentReports: false,
    roles: false,
    services: false,
    faqs: false,
    reports: false,
  });
  const [addAdminSection, setAddAdminSection] = useState(false);
  const [adminBtnLoad, setAdminBtnLoad] = useState(false);
  const [services, setServices] = useState({});
  const [updateBtnLoad, setUpdateBtnLoad] = useState(false);
  const adminType = JSON.parse(localStorage.getItem("admin")).adminType;
  const [activeAdmin, setActiveAdmin] = useState({});
  // const divRef = useRef(null);

  // const handleInputClick = () => {
  //   if (divRef.current) {
  //     divRef.current.focus();
  //   }
  // };

  const handleActiveAdmin = (admin) => {
    setActiveAdmin(admin);
    setAdminPermissions(admin.Permissions);
  };

  const handleActiveAdminDetails = e => {
    const key = e.target.name
    const value = e.target.value

    setActiveAdmin({
      ...activeAdmin,
      [key]: value
    })
  } 

  const handleAdminDetails = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAdminDetails({
      ...adminDetails,
      [key]: value,
    });
  };

  // set admin permissions
  const handleAdminPermissions = (e) => {
    const key = e.target.name;
    const checked = e.target.checked;

    const permissions = adminPermissions;
    permissions[key] = checked;

    setAdminPermissions({
      ...adminPermissions,
      [key]: checked,
    });

    // set permissions in admin details
    setAdminDetails({
      ...adminDetails,
      permissions: permissions,
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  async function getDetails() {
    await getAdmins();
    await getServiceCategories();
  }

  // get admins
  async function getAdmins() {
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/get`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setAdmins(result.admins);
    setFetchedAdmins(true);
  }

  // get service Categories
  async function getServiceCategories() {
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/category`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();

    setServices(result.categories);
  }

  // add admin to super company
  async function addAdmin(e) {
    e.preventDefault();

    setAdminBtnLoad(true);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/admin/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
      body: JSON.stringify(adminDetails),
    });

    if (!response.ok) {
      setAdminBtnLoad(false);

      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
      return;
    }

    setAdminBtnLoad(false);

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 5000,
    });

    getAdmins();
    setAddAdminSection(false);
  }

  async function updateAdminDetails(e) {
    e.preventDefault()

    activeAdmin.Permissions = adminPermissions
    const admin = JSON.parse(localStorage.getItem("admin"));
    setUpdateBtnLoad(true);
    const response = await fetch(
      `${url.mainUrl}/updateprofile/?adminId=${activeAdmin._id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
        body: JSON.stringify(activeAdmin),
      }
    );

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
      });
      setUpdateBtnLoad(false);

      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
    });

    setUpdateBtnLoad(false);
    getAdmins();
    setActiveAdmin({});
  }

  // update admin status
  async function updateAdminStatus(user){
    const admin = JSON.parse(localStorage.getItem('admin'))

    const response = await fetch(`${url.mainUrl}/admin/updatestatus/${user._id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': admin.access_token
      },
      body: JSON.stringify({
          status: user.isActive? false : true
      })
    })

    if(!response.ok){
      const result = await response.json()
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
      })

      return
    }

    const result = await response.json()
      toast.success(result.error, {
        position: toast.POSITION.TOP_CENTER,
    })

    getDetails()
  }

  function handleConfirm(admin){
    const closeToast = () => toast.dismiss()

    toast.warn(
      <div>
        <p>Are you sure you want to {admin.isActive? "Block" : "UnBlock"} {admin.name}</p>
        <div className='flex justify-evenly mt-3'>
            <button onClick={() => {updateAdminStatus(admin); closeToast()}} className='py-1 px-3 bg-green-500 rounded-md text-white'>Accept</button>
            <button className='py-1 px-3 bg-danger rounded-md text-white'>Decline</button>
        </div>
        
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  }

  return (
    <section className="py-2 h-full flex flex-col gap-3 relative">
      <div className=" flex-1 border border-primary rounded-md flex flex-col">
        <h1 className="text-primary p-2 shadow-md shadow-primary font-bold text-xl">
          All Admins
        </h1>
        <div className="flex-1 p-4 relative">
          {!admins.length ? (
            <div className="absolute left-[50%] top-[50%] -translate-x-[50%]">
              {fetchedAdmins ? (
                <div className="text-gray-500 text-xl">
                  {" "}
                  No Company Admins Found
                </div>
              ) : (
                <PropagateLoader color="#B7926B" loading={true} size={15} />
              )}
            </div>
          ) : (
            admins.map((admin) => (
              <div
                key={admin._id}
                className="flex my-3 text-primary shadow-sm border rounded-md border-primary shadow-primary items-center justify-evenly py-2"
              >
                <p className="flex-1 text-center flex flex-col gap-2 font-bold">
                  Name
                  <span className="font-[500]">{admin.name}</span>
                </p>
                <p className="flex-1 text-center flex flex-col gap-2 font-bold">
                  Company
                  <span className="font-[500]">{admin.company.name}</span>
                </p>
                <p className="flex-1 text-center flex flex-col gap-2 font-bold">
                  Email
                  <span className="font-[500]">{admin.email}</span>
                </p>
                <p className="flex-1 text-center flex flex-col gap-2 font-bold">
                  Phone
                  <span className="font-[500]">0{admin.phone.slice(3)}</span>
                </p>

                <div>
                  <button
                    className={`p-2 w-max mx-3 ${admin.isActive? "bg-red-500" : "bg-green-500"} text-white rounded-md`}
                    onClick={() => handleConfirm(admin)}
                  >
                    {admin.isActive? "Block Admin" : "Unblock Admin"}
                  </button>
                </div>

                <div>
                  <button
                    className="p-2 w-max mx-3 bg-primary text-white rounded-md "
                    onClick={() => handleActiveAdmin(admin)}
                  >
                    Update User
                  </button>
                </div>

              </div>
            ))
          )}


        </div>
      </div>

      {/* add Admin */}
      <div
        className={`absolute w-full ${
          addAdminSection ? "scale-100" : "scale-0"
        } h-full bg-white`}
      >
        <span
          onClick={() => setAddAdminSection(false)}
          className="text-red-300 text-6xl cursor-pointer rounded-full block text-center m-auto w-max"
        >
          <CiCircleRemove />
        </span>
        <div className="p-2 border border-primary rounded-md">
          <h1 className="py-2 text-primary font-bold shadow-md shadow-primary text-center text-xl">
            Add Admin
          </h1>
          <form
            action=""
            onSubmit={addAdmin}
            className="px-2 mt-2 flex-1 flex flex-col justify-between"
          >
            <div className="mb-6 flex flex-col gap-5 py-3">
              <input
                onChange={handleAdminDetails}
                type="text"
                name="name"
                id="name"
                placeholder="Admin Name"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleAdminDetails}
                type="number"
                name="phone"
                id="phone"
                placeholder="phone number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleAdminDetails}
                type="number"
                name="identity"
                id="identity"
                placeholder="Id Number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleAdminDetails}
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />

              {adminType == "super" && (
                <div>
                  <p className="text-center text-primary font-bold text-xl underline">
                    Select Permissions
                  </p>

                  <div className="text-primary">
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Dashboard</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="dashboard"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Queries</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="queries"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Admins</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="admins"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Service Providers</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="providers"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Sit Ins</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="sitins"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Corporates</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="corporates"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Company</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="company"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Incident Reports</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="incidentReports"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Roles</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="roles"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Services</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="services"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">FAQs</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="faqs"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Reports</label>
                      <input
                        onChange={handleAdminPermissions}
                        type="checkbox"
                        name="reports"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-6">
              {adminBtnLoad ? (
                <button
                  type="button"
                  className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                  disabled
                >
                  <div className="flex items-center justify-center">
                    <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                >
                  Add Admin
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* Update Admin */}
      {!Object.keys(activeAdmin).length? 
      ""
      : 
        <div
        className={`absolute w-full h-full bg-white`}
        >
        <span
          onClick={() => setActiveAdmin({})}
          className="text-red-300 text-6xl cursor-pointer rounded-full block text-center m-auto w-max"
        >
          <CiCircleRemove />
        </span>
        <div className="p-2 border border-primary rounded-md">
          <h1 className="py-2 text-primary font-bold shadow-md shadow-primary text-center text-xl capitalize">
            Update {activeAdmin.name}
          </h1>
          <form
            action=""
            onSubmit={updateAdminDetails}
            className="px-2 mt-2 flex-1 flex flex-col justify-between"
          >
            <div className="mb-6 flex flex-col gap-5 py-3">
              <input
                onChange={handleActiveAdminDetails}
                defaultValue={activeAdmin.firstName}
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Admin First Name"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
               <input
                onChange={handleActiveAdminDetails}
                defaultValue={activeAdmin.lastName}
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Admin Last Name"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleActiveAdminDetails}
                defaultValue={activeAdmin.phone}
                type="number"
                name="phone"
                id="phone"
                placeholder="phone number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleActiveAdminDetails}
                defaultValue={activeAdmin.identity}
                type="number"
                name="identity"
                id="identity"
                placeholder="Id Number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleActiveAdminDetails}
                defaultValue={activeAdmin.email}
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />

              {adminType == "super" && (
                <div>
                  <p className="text-center text-primary font-bold text-xl underline">
                    Update Permissions
                  </p>

                  <div className="text-primary">
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Dashboard</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.dashboard
                        }
                        type="checkbox"
                        name="dashboard"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Queries</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.queries
                        }
                        type="checkbox"
                        name="queries"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Admins</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.admins
                        }
                        type="checkbox"
                        name="admins"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Service Providers</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.providers
                        }
                        type="checkbox"
                        name="providers"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Sit Ins</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.sitins
                        }
                        type="checkbox"
                        name="sitins"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Corporates</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.corporates
                        }
                        type="checkbox"
                        name="corporates"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Company</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.company
                        }
                        type="checkbox"
                        name="company"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Incident Reports</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.incidentReports
                        }
                        type="checkbox"
                        name="incidentReports"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Roles</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.roles
                        }
                        type="checkbox"
                        name="roles"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Services</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.services
                        }
                        type="checkbox"
                        name="services"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">FAQs</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={activeAdmin && activeAdmin.Permissions.faqs}
                        type="checkbox"
                        name="faqs"
                      />
                    </div>
                    <div className="flex w-1/2 px-[15%] items-center justify-between mx-auto">
                      <label htmlFor="">Reports</label>
                      <input
                        onChange={handleAdminPermissions}
                        defaultChecked={
                          activeAdmin && activeAdmin.Permissions.reports
                        }
                        type="checkbox"
                        name="reports"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-6">
              {updateBtnLoad ? (
                <button
                  type="button"
                  className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                  disabled
                >
                  <div className="flex items-center justify-center">
                    <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                >
                Submit
                </button>
              )}
            </div>
          </form>
        </div>
        </div>

      }
     
      {/* add company button */}
      <button
        onClick={() => {
          setAddAdminSection(true);
          setAdminPermissions({
            admins: false,
            dashboard: false,
            queries: false,
            providers: false,
            sitins: false,
            corporates: false,
            company: false,
            incidentReports: false,
            roles: false,
            services: false,
            faqs: false,
            reports: false,
          });
        }}
        type="button"
        className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
      >
        Add Admin To Your Company
      </button>
    </section>
  );
};

export default Admins;
