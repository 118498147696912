import React, { useState } from 'react'
import SideBar from './SideBar';
import { Route, Routes, useLocation, useNavigate  } from 'react-router-dom';
import HouseCategories from './HouseCategories';
import HouseLocations from './HouseLocations';
import Properties from './Properties';

const HouseManagement = () => {
    const navigate = useNavigate()
    const [activeSection, setActiveSection] = useState('');

    const handleActiveSection = (page) => {
        console.log(page);
        setActiveSection(page);
        navigate(`./${page}`);
    };

    return (
        <section className='w-full h-full flex'>
            <div className='w-64'>   
                <SideBar activeSection={activeSection} handleActiveSection={handleActiveSection}/>
            </div>
            <div className='flex-1'>
                <Routes>
                    <Route path='/categories' element={<HouseCategories />}/>
                    <Route path='/houselocations' element={<HouseLocations />}/>
                    <Route path='/properties' element={<Properties />}/>
                    <Route path='/' element={<Properties />}/>
                </Routes>
            </div>
        </section>
    )
}

export default HouseManagement