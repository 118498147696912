import React, { useEffect, useState } from "react";
import url from "../../baseUrl";
import { toast } from "react-toastify";
import { CiCircleRemove } from "react-icons/ci";
import { act } from "@testing-library/react";

const Company = () => {
  const [companies, setCompanies] = useState([]);
  const [services, setServices] = useState({});
  const [btnLoad, setBtnLoad] = useState({});
  const [companyBtnLoad, setCompanyBtnLoad] = useState(false);
  const [activeService, setActiveService] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [addCompanySection, setAddCompanySection] = useState(false);
  const [activeCompany, setActiveCompany] = useState({})
  const [removeServiceBtnload, setRemoveService] = useState({
    index: 0,
    load: false
  })

  useEffect(() => {
    getCompanies();
  }, []);

  const handleCompanyDetails = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setCompanyDetails({
      ...companyDetails,
      [key]: value,
    });
  };

  const handleActiveService = (e) => {
    const key = e.target.name;
    const value = JSON.parse(e.target.value);

    setActiveService({
      ...activeService,
      [key]: value,
    });
  };

  async function getCompanies() {
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/company`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setCompanies(result.companies);
    await getServices();
  }

  async function getServices() {
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/category/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setServices(result.categories);
  }

  async function addService(company, serviceId) {
    const admin = JSON.parse(localStorage.getItem("admin"));
    console.log(btnLoad)

    setBtnLoad({
      ...btnLoad,
      load: true
    })

    const response = await fetch(
      `${url.mainUrl}/admin/company/service/${company._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
        body: JSON.stringify({
          service: serviceId,
        }),
      }
    );

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      setBtnLoad({
      ...btnLoad,
      load: false
    })
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
      setBtnLoad({
      ...btnLoad,
      load: false
    })
    getCompanies();
  }

  const handleConfirmAddService = (company, index) => {
    setBtnLoad({
      ...btnLoad,
      index: index
    })

    if (!activeService[company._id]) {
      toast.error("please Select Service Type", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });

      return;
    }

    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>
          Are you sure yo want to add {activeService[company._id].name} service to {company.name}?
        </p>
        <div className="flex justify-evenly mt-3">
          <button
            onClick={() => {
              addService(company, activeService[company._id]._id);
              closeToast();
            }}
            className="py-1 px-3 bg-green-500 rounded-md text-white"
          >
            Accept
          </button>
          <button className="py-1 px-3 bg-danger rounded-md text-white">
            Decline
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  };

  //remove service
  async function removeService(company, serviceId) {
    const admin = JSON.parse(localStorage.getItem("admin"));

    setRemoveService({
      ...removeServiceBtnload,
      load: true
    })

    const response = await fetch(
      `${url.mainUrl}/admin/company/service/${company._id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: admin.access_token,
        },
        body: JSON.stringify({
          service: serviceId,
        }),
      }
    );

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });

      setBtnLoad({
      ...btnLoad,
      load: false
    })
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
      setBtnLoad({
      ...btnLoad,
      load: false
    })
    getCompanies();
    setActiveCompany({})
    setRemoveService({
      index: 0,
      load: false
    })
  }

  // confirm remove service
  const handleConfirmRemoveService = (service, index) => {
    setRemoveService({
      ...removeServiceBtnload,
      index: index
    })

    console.log(service)
    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>
          Are you sure you want to remove {service.name} from {activeCompany.name}?
        </p>
        <div className="flex justify-evenly mt-3">
          <button
            onClick={() => {
              removeService(activeCompany, service._id);
              closeToast();
            }}
            className="py-1 px-3 bg-green-500 rounded-md text-white"
          >
            Accept
          </button>
          <button className="py-1 px-3 bg-danger rounded-md text-white">
            Decline
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  }

  // add company
  async function addCompany(e) {
    e.preventDefault();

    setCompanyBtnLoad(true);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/admin/company`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
      body: JSON.stringify(companyDetails),
    });

    if (!response.ok) {
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeButton: true,
      });

      setCompanyBtnLoad(false);
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      closeButton: true,
    });

    setAddCompanySection(false);
    setCompanyBtnLoad(false);
    getCompanies();
  }

  return (
    <section className="h-full flex flex-col gap-2 relative">
      {/* add service to your company */}
      <div className="border border-primary flex-1 rounded-md">
        <h1 className="p-2 text-center text-primary text-xl shadow-md shadow-primary">
          Add Service To your company
        </h1>
        <div className="p-2 flex flex-col gap-2">
          {companies.length &&
            companies.map((company, index) => {
              return (
                <div className="flex px-8 gap-8 items-center border border-primary py-2 rounded-md">
                  <p onClick={() => setActiveCompany(company)} className="flex-1 text-center font-bold text-primary text-xl cursor-pointer">
                    {company.name}
                  </p>
                  <select
                    name={company._id}
                    id="service"
                    onChange={handleActiveService}
                    className="flex-1 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                  >
                    <option selected disabled>
                      Choose Service
                    </option>
                    {services.length &&
                      services.map((service) => {
                        if (
                          !company.services.some(
                            (companyService) =>
                              companyService._id == service._id
                          )
                        ) {
                          return (
                            <option
                              key={service._id}
                              value={JSON.stringify(service)}
                              className="py-2"
                            >
                              {service.name}
                            </option>
                          );
                        }
                      })}
                  </select>
                  {(btnLoad.index == index && btnLoad.load) ? (
                    <button
                      type="button"
                      className="flex-1 px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                      disabled
                    >
                      <div className="flex items-center justify-center">
                        <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                      </div>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={()=>handleConfirmAddService(company, index)}
                      className="flex-1 px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                    >
                      Add Service
                    </button>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      {/* view company details */}

      {
        !Object.keys(activeCompany).length? ""
        :

        <div className="absolute inset-0 m-auto border border-primary w-3/4 h-3/4 bg-white rounded-md flex flex-col">
            <span
              onClick={()=> setActiveCompany({})}
              className="text-red-300 text-6xl cursor-pointer rounded-full block mx-auto text-center m-auto w-max"
            >
              <CiCircleRemove />
            </span>
            <h1 className="py-2 text-primary font-bold text-xl text-center shadow-md shadow-primary">{activeCompany.name}</h1>
            <div>
              <h2 className="text-center text-primary font-bold py-2 border-b-2 border-primary">Details</h2>
              <div className="flex flex-col gap-1 font-bold text-primary py-2">
                <p className="flex justify-between w-1/2 m-auto">Company Share <span className="font-[400]">{activeCompany.companyShare || "---"}</span></p>
                <p className="flex justify-between w-1/2 m-auto">Registration Number <span className="font-[400]">{activeCompany.registrationNumber}</span></p>
                <p className="flex justify-between w-1/2 m-auto">Phone 1<span className="font-[400]">{activeCompany.phone}</span></p>
                <p className="flex justify-between w-1/2 m-auto">Phone 2<span className="font-[400]">{activeCompany.owner.phone}</span></p>
                <p className="flex justify-between w-1/2 m-auto">Email<span className="font-[400]">{activeCompany.owner.email}</span></p>
              </div>
            </div>
            <div className="flex-1 flex flex-col overflow-hidden">
              <h2 className="text-center text-primary font-bold py-2 border-b-2 border-primary">Details</h2>
              <div className="flex-1 overflow-y-auto">
                {
                  activeCompany.services.map((service, index) => {
                    return <div className="flex items-center justify-between px-5 py-2">
                    <p className="text-primary font-bold">{service.name}</p>
                    <div className="">
                      {(removeServiceBtnload.index == index && removeServiceBtnload.load) ? (
                        <button
                          type="button"
                          className="w-full px-3 py-2 text-white bg-primary focus:outline-none rounded-md"
                          disabled
                        >
                          <div className="flex items-center justify-center">
                            <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                          </div>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          onClick={() => handleConfirmRemoveService(service, index)}
                          className="w-full px-3 py-2 text-white bg-primary focus:outline-none rounded-md"
                        >
                          Remove service
                        </button>
                      )}
                    </div>
                  </div>
                  })
                }
                
              </div>
            </div>
        </div>
      }
      

      {/* add company */}
      <div
        className={`absolute w-full ${
          addCompanySection ? "scale-100" : "scale-0"
        } h-full bg-white`}
      >
        <span
          onClick={() => setAddCompanySection(false)}
          className="text-red-300 text-6xl cursor-pointer rounded-full block text-center m-auto w-max"
        >
          <CiCircleRemove />
        </span>
        <div className="p-2 border border-primary rounded-md">
          <h1 className="py-2 text-primary font-bold shadow-md shadow-primary text-center text-xl">
            Add Company
          </h1>
          <form
            action=""
            onSubmit={addCompany}
            className="px-2 mt-2 flex-1 flex flex-col justify-between"
          >
            <div className="mb-6 flex flex-col gap-5 py-3">
              <input
                onChange={handleCompanyDetails}
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleCompanyDetails}
                type="text"
                name="companyRegistrationNumber"
                id="companyRegistrationNumber"
                placeholder="Company Registration Number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleCompanyDetails}
                type="number"
                name="companyShare"
                id="companyShare"
                placeholder="Company Share"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleCompanyDetails}
                type="number"
                name="companyPhone"
                id="companyPhone"
                placeholder="Company Phone"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleCompanyDetails}
                type="email"
                name="userContactEmail"
                id="userContactEmail"
                placeholder="Company Contact Email"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <select
                name="service"
                id="service"
                onChange={handleCompanyDetails}
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
              >
                <option selected disabled>
                  Service the company is offering
                </option>
                {services.length &&
                  services.map((service) => (
                    <option
                      key={service._id}
                      value={service._id}
                      className="py-2"
                    >
                      {service.name}
                    </option>
                  ))}
              </select>

              <input
                onChange={handleCompanyDetails}
                type="name"
                name="userContactFirstName"
                id="userContactFirstName"
                placeholder="Company User Contact First Name"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />

              <input
                onChange={handleCompanyDetails}
                type="name"
                name="userContactLastName"
                id="userContactLastName"
                placeholder="Company User Contact Last Name"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />

              <input
                onChange={handleCompanyDetails}
                type="number"
                name="userContactId"
                id="userContactId"
                placeholder="Company User Id number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
              <input
                onChange={handleCompanyDetails}
                type="number"
                name="userContactPhone"
                id="userContactPhone"
                placeholder="Company user Contant phone number"
                className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
              />
            </div>

            <div className="mb-6">
              {companyBtnLoad ? (
                <button
                  type="button"
                  className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                  disabled
                >
                  <div className="flex items-center justify-center">
                    <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                >
                  Add Company
                </button>
              )}
            </div>
          </form>
        </div>
      </div>

      {/* add company button */}
      <button
        onClick={() => setAddCompanySection(true)}
        type="button"
        className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
      >
        Add Company
      </button>
    </section>
  );
};

export default Company;
