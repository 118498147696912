import React from 'react'
import Dial from '../../images/dial.png'
import {MdOutlineDashboard} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const SideBar = ({active, handleActivePage}) => {
    const navigate = useNavigate()

    const permissions = JSON.parse(localStorage.getItem("adminDetails")).Permissions
    const handleLogOut = ()=>{
        localStorage.clear()
        navigate('/login', {replace: true})
    }

    return (
        <section className='flex flex-col max-h-screen bg-primary border border-white overflow-y-auto'>
            <div className='w-full flex items-center justify-evenly py-3 text-white'>
                <img src={`${Dial}`} className='w-14 h-14' alt="logo" />
                <p>Dial A Service</p>
            </div>
            <div className='border-2 border-t-gray-300 flex-1 rounded-md flex flex-col gap-1 py-2 px-1'>
                {permissions.dashboard && <div onClick={()=> handleActivePage('dashboard')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "dashboard"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Dashboard
                    </span>
                </div>}

                {permissions.queries && <div onClick={()=> handleActivePage('queries')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "queries"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Queries
                    </span>
                </div>}

                {permissions.houseManagement && <div onClick={()=> handleActivePage('houses')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "houses"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        House Management
                    </span>
                </div>}


                {permissions.houseBookings && <div onClick={()=> handleActivePage('housebookings')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "housebookings"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        House Bookings
                    </span>
                </div>}

                {permissions.providers && <div onClick={()=> handleActivePage('serviceproviders')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "serviceproviders"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Service Providers
                    </span>
                </div>}
                {permissions.sitins && <div onClick={()=> handleActivePage('sitins')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "sitins"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Sit Ins
                    </span>
                </div>}
                {permissions.corporates && <div onClick={()=> handleActivePage('corporates')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "corporates"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Corporates
                    </span>
                </div>}
                    {permissions.company && <div onClick={()=> handleActivePage('company')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "company"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Company
                    </span>
                </div>}
                {permissions.incidentReports && <div onClick={()=> handleActivePage('incidents')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "incidents"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Incident Reports
                    </span>
                </div>}
                {permissions.roles && <div onClick={()=> handleActivePage('roles')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "roles"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Roles
                    </span>
                </div>}
                {permissions.services && <div onClick={()=> handleActivePage('services')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "services"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Services
                    </span>
                </div>}
                {permissions.admins && <div onClick={()=> handleActivePage('admins')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "admins"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Admins
                    </span>
                </div>}
                {permissions.faqs && <div onClick={()=> handleActivePage('faqs')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "faqs"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        FAQs
                    </span>
                </div>}
                {permissions.reports && <div onClick={()=> handleActivePage('reports')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "reports"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                    <span>
                        <MdOutlineDashboard />
                    </span>
                    <span>
                        Reports
                    </span>
                </div>}

                {
                    permissions.uploadApp && 

                    <div onClick={()=> handleActivePage('upload')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${active == "upload"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
                        <span>
                            <MdOutlineDashboard />
                        </span>
                        <span>
                            Upload Apps
                        </span>
                    </div>
                }
                
               
            </div>
            <div>
                <p onClick={handleLogOut} className='text-red-500 cursor-pointer bg-white mx-2 my-1 text-center rounded-md py-2 font-bold'>Log Out</p>
            </div>
        </section>
    )
}

export default SideBar