import React, { useEffect, useRef, useState } from 'react'
import url from '../../../baseUrl'
import { IoMdCloudUpload } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";

import { toast } from 'react-toastify';

const House = ({propertyId, setPropertyId, properties}) => {
    const [houses, setHouses] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesFetched, setCategoriesFecthed] = useState(false)
    const [filteredCategories, setFilteredCategories] = useState([])
    const [images, setImages] = useState([])
    const [houseData, setHouseData] = useState({})
    const fileRef = useRef(null)
    const [addSection, setAddSection] = useState(false)
    const [housesFetch, setHousesFetched] = useState(false)
    const [activeHouseImage, setHouseActiveImage] = useState([])

    useEffect(()=>{
        getCategories()
        getHouses(propertyId)
    }, [])

    const handleHouseImage = (_id, navigate) => {
        setHouseActiveImage(prevState => {
            const houses = prevState.map(house => {
                if (house._id === _id) {
                    if (navigate) {
                        if (house.totalImages > house.activeImage + 1) {
                            return { ...house, activeImage: house.activeImage + 1 };
                        } else {
                            return { ...house, activeImage: 0 };
                        }
                    } else {
                        if (house.activeImage > 0) {
                            return { ...house, activeImage: house.activeImage - 1 };
                        } else {
                            return { ...house, activeImage: house.totalImages - 1 };
                        }
                    }
                }
                return house;
            });
            return houses;
        });
    }
    
    useEffect(() => {
        const activeProperty = properties.filter(property => property._id == propertyId);
        const locationCategories = activeProperty[0].locationCategory.categories.map(categ => categ._id);
        setFilteredCategories(()=>{
            const filter = categories.filter(categ => {
                if(locationCategories.includes(categ._id)){
                    return categ
                }
            })
            return filter || []
        })

    }, [propertyId, categoriesFetched])

    const handleHouseData = (key, value) => {
        setHouseData(()=> {
            const exisingData = {...houseData}
            exisingData[key] = value

            return exisingData
        })
    }

    const handleFileChange = (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];
      
        if (file) {
          const reader = new FileReader();
      
          reader.onloadend = () => {
            const base64String = reader.result;
            // Push the base64 encoded string into your array
            setImages(() => {
                const currentImages = [...images];
                currentImages.push(base64String)

                return currentImages
            })
          };
      
          reader.readAsDataURL(file);
        }
    };

    const getCategories = async ()=>{
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            const response = await fetch(`${url.mainUrl}/admin/house/category/`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: admin.access_token,
                }
            })

            if(!response.ok){
                const result = await response.json()
                console.log(result)
                return
            }

            const data = await response.json()
            setCategories(data)
            setTimeout(()=> {
                setCategoriesFecthed(true)
            }, 300)

        }
        catch(error){
            console.log(error)
        }
    }

    async function getHouses(propertyId){
        setHouses([])
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            const response = await fetch(`${url.mainUrl}/admin/house/?propertyId=${propertyId}`, {
                method: "GET",
                headers: {
                    Authorization: admin.access_token,
                    "Content-Type": "application/json"
                }
            })

            if(!response.ok){
                const result = await response.json()
                console.log(result)
                return
            }

            const result = await response.json()
            const filteredResult = result.map((house)=>{
                return {
                    _id: house._id,
                    totalImages: house.images.length,
                    activeImage: 0
                }
            })

            setHouseActiveImage(filteredResult)
            setHouses(result)
            setHousesFetched(true)

        }
        catch(error){
            console.log(error)
        }
    }

    async function addHouse(e){
        e.preventDefault()

        houseData['property'] = propertyId
        if(images.length){
            houseData["images"] = images
        }
        else{
            toast.error("Select atleast one image", {
                position: toast.POSITION.TOP_CENTER
            })

            return
        }
        
        const admin = JSON.parse(localStorage.getItem("admin"));
        try{
            toast.info("Adding house to property...", {
                position: toast.POSITION.TOP_CENTER
            })  
            
            const response = await fetch(`${url.mainUrl}/admin/house/`, {
                method: "POST",
                headers: {
                    Authorization: admin.access_token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(houseData)
            })

            if(!response.ok){
                const result = await response.json()
                toast.error(result.error, {
                    position: toast.POSITION.TOP_CENTER
                })

                return
            }

            const result = await response.json()
            toast.success(result.message, {
                position: toast.POSITION.TOP_CENTER
            })  

            setAddSection(false)
            getHouses()

        }
        catch(error){
            console.log(error)
        }
    }


    return (
        <section className='relative w-full h-full text-primary flex flex-col gap-2'>
            <div className='absolute top-0 left-0 flex items-center gap-2 text-sm'>
                {properties.length &&
                    <>
                        <label htmlFor="propertySelected" className='font-bold'>Property Selected</label>
                        <select onChange={(e) => {setPropertyId(e.target.value); setHousesFetched(false); getHouses(e.target.value)}} name="propertySelected" id="propertySelected" className='w-max text-primary px-4 py-2 border border-primary rounded-md '>
                            {properties.map(property => {
                                return <option value={property._id} selected={property._id == propertyId}>{property.name}</option>
                            }) }
                        </select>
                        <p className='font-bold'>Location: <span className='font=[400]'>{properties.map(property => {if(property._id == propertyId) return property.locationCategory.name})}</span></p>
                    </>
                   
                }
                
            </div>
            <button type='button' onClick={()=> setPropertyId("")} className='block mx-auto bg-primary w-32 text-white py-2 rounded-md absolute right-2 top-0'>All Properties</button>
           
            {
                !addSection? 
                 <>
                    {/* display houses */}
                    <button type='button' onClick={() => setAddSection(true)} className='block mx-auto bg-primary w-32 text-white py-2 rounded-md'>Add House</button>
                    <div className='w-full'>
                        <h1 className='p-2 font-bold text-center border border-primary rounded-md '>All Houses</h1>
                        <div className='relative flex items-center flex-wrap p-2 h-[700px] justify-evenly w-full gap-2 overflow-y-auto'>
                            {
                                !houses.length? 
                                   !housesFetch? <p >Loading ...</p> : ""
                                : 
                                houses.map(house => {
                                    const category = house.property.locationCategory.categories.filter(categ => categ._id == house.category._id)
                                    let activeImage = activeHouseImage.filter(activeHouse => activeHouse._id == house._id)[0].activeImage
                                    try{
                                        return <div className='p-1 border h-max border-primary rounded-md w-max'>
                                        <div className='w-64 h-64 overflow-hidden rounded-md relative'>
                                            <span onClick={()=> handleHouseImage(house._id, false)} className='p-2 text-2xl font-bold flex items-center justify-center h-10 w-10 rounded-full cursor-pointer text-white bg-gray-400 absolute left-0 top-0 bottom-0 m-auto'>
                                                <MdNavigateBefore />
                                            </span>
                                                <img className='w-full h-full object-cover' src={house.images[activeImage]} alt="house image" />
                                            <span onClick={()=> handleHouseImage(house._id, true)}  className='p-2 text-2xl font-bold flex items-center justify-center h-10 w-10 rounded-full cursor-pointer text-white bg-gray-400 absolute right-0 top-0 bottom-0 m-auto'>
                                                <MdNavigateNext />
                                            </span>
                                        </div>
                                        <p className='font-bold'>Category: <span className='font-[400]'>{house.category.name}</span></p>
                                        <p className='font-bold'>Type: <span className='font-[400]'>{house.category.type}</span></p>
                                        <p className='font-bold'>Owners Price: <span className='font-[400]'>{house.price}</span></p>
                                        <p className='font-bold'>Customers Price: <span className='font-[400]'>{category[0].price}</span></p>
                                        <p className='font-bold'>Floor: <span className='font-[400]'>{house.floor || ""}</span></p>
                                        <p className='font-bold'>Room Number: <span className='font-[400]'>{house.roomNumber || ""}</span></p>
                                    </div>
                                    }
                                    catch(error){
                                        console.log(error)
                                    }
                                
                                })
                            }

                            { housesFetch? houses.length? "" : <p>No Houses Found for this perticular property</p> : ""
}
                            
                        
                        </div>
                    </div>
                </>
                :
                
                <div>
                                {/* add house */}
                <span onClick={() => setAddSection(false)} className=' block w-max mx-auto text-red-700 text-5xl cursor-pointer'>
                        <CiCircleRemove />
                </span>
                <h1 className='font-bold text-center p-2 border border-primary'>Add House</h1>
                <form action="" onSubmit={addHouse} className='p-2 flex flex-col gap-2'>
                    <select name="category" onChange={(e)=> handleHouseData(e.target.name, e.target.value)} id="" className="text-primary px-4 py-2 border border-primary rounded-md w-full">
                        <option selected disabled>Choose House Category</option>
                        {
                            filteredCategories.length && filteredCategories.map(category => {
                                return <option value={category._id} className='py-2'><span>{category.name}</span> <span className='font-bold'>{category.type}</span></option>
                            })
                        }
                    </select>
                    {/* ['firstFloor', 'secondFloor', 'thirdFloor', 'fourthFloor', 'fifthFloor', 'sixthFloor'] */}
                    <select name="floor" onChange={(e)=> handleHouseData(e.target.name, e.target.value)} id="" className="text-primary px-4 py-2 border border-primary rounded-md w-full">
                        <option selected disabled>Choose floor</option>
                        <option value='groundfloor'> Ground Floor</option>
                        <option value='firstFloor'> First Floor</option>
                        <option value='secondFloor'> Second Floor</option>
                        <option value='thirdFloor'> third Floor</option>
                        <option value='fourthFloor'> Fourth Floor</option>
                        <option value='fifthFloor'> Fifth Floor</option>
                        <option value='sixthFloor'> Sixth Floor</option>
                    </select>
                    <input type="number" name='price' onChange={(e)=> handleHouseData(e.target.name, e.target.value)} className="text-primary px-4 py-2 border border-primary rounded-md w-full" placeholder='Enter price' />
                    <input type="text" name='roomNumber' onChange={(e)=> handleHouseData(e.target.name, e.target.value)} className="text-primary px-4 py-2 border border-primary rounded-md w-full" placeholder='Enter roomNumber' />
                    <input type='file' ref={fileRef} onChange={handleFileChange} hidden />
                    <div>
                        {
                            !images.length? "":
                            <div className='flex gap-1 w-full overflow-x-auto p-2'>
                                {
                                    images.map((image, index) => {
                                        return <div className='relative w-64 h-64 overflow-hidden rounded-md border border-primary'>
                                                    <img className='w-full h-full object-contain' src={image} alt="house image" />
                                                    <span id={index} onClick={(e)=> setImages(()=> images.filter((img, imgIndex ) => index != imgIndex  ))} className='absolute top-2 right-2 text-red-700 text-3xl cursor-pointer'>
                                                        <CiCircleRemove />
                                                    </span>
                                                </div>
                                    })
                                }
                            </div>
                        }
                       
                        <div onClick={() => fileRef.current.click()} className='w-full text-center border border-primary rounded-md cursor-pointer'>
                            <IoMdCloudUpload className='text-3xl block w-max mx-auto'/>
                            <span>Upload Image</span>
                        </div>
                    </div>  
                    <textarea name="specifications" onChange={(e)=> handleHouseData(e.target.name, e.target.value)} placeholder='Other information' className="text-primary px-4 py-2 border border-primary rounded-md w-full" id="" cols="30" rows="10"></textarea>
                    <button type='submit' className='block mx-auto bg-primary w-32 text-white py-2 rounded-md'>submit</button>
                </form>

            </div>
            }
            

        </section>
    )
}

export default House