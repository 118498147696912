import React, { useEffect, useState } from "react";
import url from "../../baseUrl";

const CorporateQueries = () => {
  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    getCorporateQueries();
  }, []);

  async function getCorporateQueries() {
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/query/corporate`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setCorporates(result);
  }

  return (
    <section className="h-full p-2">
      <div className="border border-primary rounded-md h-full flex flex-col">
        <h1 className="text-primary font-bold text-xl text-center py-2 shadow-md shadow-primary">
          Corporate Queries
        </h1>
        <div className="flex-1 p-2 overflow-y-auto flex flex-col gap-2">
          {!corporates.length
            ? ""
            : corporates.map((corporate) => {
                return (
                  <div className="flex p-2 border border-primary rounded-md">
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Name
                      <span className="font-[500] pt-2 text-center">
                        {corporate.requester.firstName + " " + corporate.requester.lastName}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Phone
                      <span className="font-[500] pt-2 text-center">
                        {formatPhone(corporate.phone)}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Company Name
                      <span className="font-[500] pt-2 text-center">
                        {corporate.companyName}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Email
                      <span className="font-[500] pt-2 text-center">
                        {corporate.email}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Service Type
                      <span className="font-[500] pt-2 text-center">
                        {corporate.serviceName}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Service Date
                      <span className="font-[500] pt-2">
                        {formatISODate(corporate.date, true).date}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Requested Date
                      <span className="font-[500] pt-2">
                        {formatISODate(corporate.createdAt, true).date +
                          " " +
                          formatISODate(corporate.createdAt, true).time}
                      </span>
                    </p>
                    <p className="flex-1 text-center flex flex-col font-bold text-primary p-2">
                      Status
                      <span className="font-[500] pt-2">{corporate.status}</span>
                    </p>
                  </div>
                );
              })}
        </div>
      </div>
    </section>
  );
};

function formatISODate(isoString, createdAt) {
  const date = new Date(isoString);

  // Check if the input ISO string is valid
  if (isNaN(date)) {
    return { date: "Invalid Date", time: "Invalid Time" };
  }

  if (createdAt) {
    date.setHours(date.getHours() + 3);
  }
  // Subtract 3 hours from the date
  date.setHours(date.getHours() - 3);

  const optionsDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return { date: formattedDate, time: formattedTime };
}

function formatPhone(phone) {
  const formatedPhone = phone.toString().slice(3);

  return 0 + formatedPhone;
}

export default CorporateQueries;
