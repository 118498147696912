import React, { useEffect, useState } from 'react'
import url from '../../baseUrl'
import { toast } from 'react-toastify'
import { CiCircleRemove } from 'react-icons/ci'
import { PropagateLoader } from 'react-spinners';

const Roles = () => {
  const [users, setUsers] = useState([])
  const [btnLoad, setBtnLoad] = useState({
    load: false,
    index: null
  })
  const [companies, setCompanies] = useState([])
  const [filter, setFilter] = useState(false)
  const [activeUser, setActiveUser] = useState({})
  const [filteredBySearch, setfilteredBySearch] = useState([])
  const [popUp, setPopUp] = useState(false)
  const [services, setServices] = useState([])
  const [activeService, setActiveService] = useState('')
  const adminDetails = JSON.parse(localStorage.getItem('adminDetails'))
  const [fetchedUsers, setFetchedUsers] = useState(false)

  useEffect(()=>{
    getUsers()
    getCompanies()
    getServices()
  }, [])

  const handleActiveUser = (id, index)=>{
    setPopUp(true)
    setActiveUser(id)

    setBtnLoad({
      ...btnLoad,
      index: index
    })
  }


  async function getServices(){
    const admin = JSON.parse(localStorage.getItem('admin'))

    const response = await fetch(`${url.mainUrl}/admin/category/`, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': admin.access_token
        }
    })

    if(!response.ok){
        return
    }

    const result = await response.json()

    setServices(result.categories)

  }

  async function getUsers(){
    const admin = JSON.parse(localStorage.getItem('admin'))

    const response = await fetch(`${url.mainUrl}/admin/users`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': admin.access_token
        }
    })

    if(!response.ok){
      return
    }

    const result = await response.json()
    const filteredResults = result.users.filter(user => {
      if(user.roles.length == 1 && user.roles.includes('requester')){
        return user
      }
    })

    setUsers(filteredResults)
    setFetchedUsers(true)
  }

  async function assignProviderRole(company, service){
    const admin = JSON.parse(localStorage.getItem('admin'))
    setBtnLoad({
      ...btnLoad,
      load: true
    })

    setPopUp(false)
    const response = await fetch(`${url.mainUrl}/admin/role/${company._id}/${activeUser._id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': admin.access_token
        },
        body: JSON.stringify({
          service: service._id
        })
    })

    if(!response.ok){
      const result = await response.json();
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })
      setBtnLoad({
        ...btnLoad,
        load: false
      })

      return;
    }

    const result = await response.json();

    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000
    })

    setBtnLoad({
      ...btnLoad,
      load: false
    })

    getUsers()

  }

  const handleAssignRole = (company) => {
    const closeToast = () => toast.dismiss()
    let companyService = {}
    if(adminDetails.company._id == company._id){
      if(!activeService){
        toast.error("Service is required", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        })

        return
      }
      companyService = JSON.parse(activeService)
    }
    else{
      companyService = company.services[0]
    }

    toast.warn(
      <div>
        <p>Are you sure you want to assign Service Provider Role to 
          <span className='font-bold'> {activeUser.name}</span> at 
          <span className='font-bold'> {company.name}</span> 
          {adminDetails.company._id == company._id && <span> to offer <span className='font-bold'>{companyService.name}</span></span>}?</p>
        <div className='flex justify-evenly mt-3'>
            <button onClick={() => {assignProviderRole(company, companyService); closeToast()}} className='py-1 px-3 bg-green-500 rounded-md text-white'>Accept</button>
            <button className='py-1 px-3 bg-danger rounded-md text-white'>Decline</button>
        </div>
        
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  }

  async function getCompanies(){
    const admin = JSON.parse(localStorage.getItem('admin'))
    const response = await fetch(`${url.mainUrl}/admin/company`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': admin.access_token
      }
    })

    if(!response.ok){
      return
    }

    const result = await response.json()
    setCompanies(result.companies)

  }

  return (
    <section className='p-2 h-full'>
      <div className='border border-primary rounded-md h-full flex flex-col overflow-y-auto'>
        <h1 className='text-primary p-2 shadow-md shadow-primary text-center text-xl font-bold'>Add Role to Users</h1>
        <div className='flex-1 flex flex-col p-2 relative'>
          <div className='py-4'>
            <input
              name="service"
              id="service"
              placeholder='Search ...'
              className="w-1/2 block mx-auto px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
              />
          </div>
          <div className='flex-1 overflow-y-auto flex flex-col gap-2'>

          {

              !users.length?
              
                <div className='absolute left-[50%] top-[50%] -translate-x-[50%]'>
                   {
                          fetchedUsers? 
                              <div className='text-gray-500 text-xl'> No Requesters Found</div>
                          :
                          <PropagateLoader color="#B7926B" loading={true} size={15} />
                      }
                </div>
              :

              !filter && 
              users.map((user, index) => {
                  
                return <div className='p-2 flex items-center justify-evenly border border-primary rounded-md text-primary'>
                    <p className='flex-1 font-bold flex flex-col items-center'>Name
                      <span className='font-[400]'>{user.name}</span>
                    </p>
                    <p className='flex-1 font-bold flex flex-col items-center'>Email
                      <span className='font-[400]'>{user.email}</span>
                    </p>
                    <p className='flex-1 font-bold flex flex-col items-center'>Phone
                      <span className='font-[400]'>{user.phone}</span>
                    </p>
                    <p className='flex-1 font-bold flex flex-col items-center'>Id
                      <span className='font-[400]'>{user.identity}</span>
                    </p>
                
                  <div className={`flex-1 p-2`}>
                    {
                      btnLoad.load && btnLoad.index == index? <button
                      type="button"
                      className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                      disabled
                      >
                        <div className="flex items-center justify-center">
                            <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                        </div>
                      </button> :  <button
                          type="submit"
                          onClick={()=> handleActiveUser(user, index)}
                          className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                      >
                      Assign Provider Role
                      </button>
                    }
                  </div>
                </div>
              }) 
              
          }
           {

              !users.length? "" 
              : 
              filter && filteredBySearch.map((user, index) => {
                  
                return <div className='p-2 flex items-center justify-evenly border border-primary rounded-md text-primary'>
                    <p className='flex-1 font-bold flex flex-col items-center'>Name
                      <span className='font-[400]'>{user.name}</span>
                    </p>
                    <p className='flex-1 font-bold flex flex-col items-center'>Email
                      <span className='font-[400]'>{user.email}</span>
                    </p>
                    <p className='flex-1 font-bold flex flex-col items-center'>Phone
                      <span className='font-[400]'>{user.phone}</span>
                    </p>
                    <p className='flex-1 font-bold flex flex-col items-center'>Id
                      <span className='font-[400]'>{user.identity}</span>
                    </p>
                  <div className={`flex-1 p-2`}>
                    {
                      btnLoad.load && btnLoad.index == index? <button
                      type="button"
                      className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                      disabled
                      >
                        <div className="flex items-center justify-center">
                            <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                        </div>
                      </button> :  <button
                          type="submit"
                          onClick={()=> handleActiveUser(user, index)}
                          className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                      >
                      Assign Provider Role
                      </button>
                    }
                  </div>
                </div>
              }) 
              
          }
          </div>

          {
            popUp && 
            <div className='absolute inset-0 m-auto h-3/4 w-1/2 bg-primary bg-opacity-90 pt-5'>
              <h1 className='text-xl font-bold py-2 text-center border-b-2 border-white'>Select Company</h1>
              <div className='flex flex-col gap-1 p-2'>
                {
                  companies.length && 
                  companies.map(company => {
                    return  <p 
                    key={company._id} 
                    id={company._id} 
                    onClick={() => {
                      if(company._id != adminDetails.company._id){
                        handleAssignRole(company)
                      }
                     }} 
                    className={`text-center rounded-md py-2  ${company._id == adminDetails.company._id? "": 'hover:bg-white cursor-pointer' } relative flex items-center justify-evenly`}>
                      {company.name}
                      {
                       company._id == adminDetails.company._id && (
                          <>
                             <select 
                              name="service" 
                              id="service"
                              onChange={e => setActiveService(e.target.value)}
                              className="w-1/3 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                             >
                              <option sisabled value={""}>Select Service</option>
                              {services.length && 
                                  services.map((service)=> {
                                      return <option key={service._id} value={JSON.stringify(service)} className='hover:bg-slate-400 py-2'>{service.name}</option>
                              })
                              }
                          </select>
                          <button onClick={(e)=> handleAssignRole(company)} className='p-2 rounded-md bg-white cursor-pointer'>Submit</button>
                          </>
                       )}
                      
                    </p>
                  })
                }
              </div>

              <span onClick={()=> {setPopUp(false); setActiveUser({})}} className='text-5xl text-red-400 hover:text-red-500 cursor-pointer absolute top-2 right-2'>
                <CiCircleRemove />
              </span>
          </div>
          }
          
        </div>
      </div>
    </section>
  )
}

export default Roles