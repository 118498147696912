import io from 'socket.io-client';
import url from '../baseUrl';

const SERVER_URL = url.socketUrl;

async function connectToSocketIo() {
  return new Promise(async (resolve, reject) => {
    try {
      const admin = JSON.parse(localStorage.getItem('admin'));
      const token = admin.access_token;
      const socket = io(SERVER_URL, {
        query: { token },
        transports: ['websocket'],
      });

      let isConnected = false;

      socket.on('connect', () => {
        isConnected = true;
        console.log('Connected to Socket.io server');
        resolve({ connected: isConnected, socket });
      });

      socket.on('disconnect', () => {
        isConnected = false;
        console.log('Disconnected from Socket.io server');
        reject('Disconnected from Socket.io server');
      });

      socket.on('connect_error', (error) => {
        isConnected = false;
        console.error('Connection error:', error);
        reject('Connection error');
      });

      // Handle other socket events here
    } catch (error) {
      console.error('Socket.io connection error:', error);
      reject('Socket.io connection error');
    }
  });
}

export default connectToSocketIo;
