import React, { useEffect, useRef, useState } from 'react'
import url from '../../baseUrl'
import { toast } from 'react-toastify'

const UploadApps = () => {
    const [requestorApps, setRequestorApps] = useState([])
    const [providerApps, setProviderApps] = useState([])
    const [requestorBtnLoad, setRequestorBtnLoad] = useState(false)
    const [providerBtnLoad, setProviderBtnLoad] = useState(false)
    const [requestorApp, setRequestorApp] = useState({name: "No App Selected"})
    const [providerApp, setProviderApp] = useState({name: "No App Selected"})
    const [downloads, setDownloads] = useState([])

    const requestorRef = useRef(null)
    const providerRef = useRef(null)

    useEffect(()=>{
        getProviderApps()
        getRequestorApps()
        getAppDownloads()
    }, [])

    console.log(downloads)

    const handleRequstorApp = (e) => {
        const file = e.target.files[0];

        if (file) {
            setRequestorApp(file);
        }

    };

    const handleProvicerApp = (e) => {
        const file = e.target.files[0];

        if (file) {
            setProviderApp(file);
        }
    }

    const getAppDownloads = async () => {
        const admin = JSON.parse(localStorage.getItem('admin'))

        const response = await fetch(`${url.mainUrl}/admin/app/totaldownloads`, {
            headers: {
                "Content-Type": 'application/json',
                'Authorization': admin.access_token
            }
        })

        if(!response.ok){
            return
        }

        const result = await response.json()

        setDownloads(result)
    }

    // get requestor apps
    async function getRequestorApps(){
        const admin = JSON.parse(localStorage.getItem("admin"))

        const response = await fetch(`${url.mainUrl}/admin/app?appType=requestor`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        });

        if(!response.ok){
            return
        }

        const result = await response.json()

        setRequestorApps(result)
    }

    // get service provider app
    async function getProviderApps() {
        const admin = JSON.parse(localStorage.getItem("admin"))

        const response = await fetch(`${url.mainUrl}/admin/app?appType=provider`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        });

        if(!response.ok){
            return
        }

        const result = await response.json()

        setProviderApps(result)
    }

    async function addRequestorApp(){
        const admin = JSON.parse(localStorage.getItem("admin"))
        if(!requestorApp.name){
            toast.error("No App Selected", {
                position: toast.POSITION.TOP_CENTER
            })

            return
        }

        setRequestorBtnLoad(true)

        const formData = new FormData()
        formData.append("file", requestorApp)

        const response = await fetch(`${url.mainUrl}/admin/app?appType=requestor`, {
            method: 'POST',
            headers: {
                "Authorization": admin.access_token
            },
            body: formData
        })

        if(!response.ok){
            const result = await response.json()
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER
            })

            setRequestorBtnLoad(false)
            return
        }

        const result = await response.json()

        toast.success(result.message, {
            position: toast.POSITION.TOP_CENTER
        })

        getRequestorApps()
        setRequestorBtnLoad(false)

    }

    async function addProviderApp(){
        const admin = JSON.parse(localStorage.getItem("admin"))
        if(!providerApp.name){
            toast.error("No App Selected", {
                position: toast.POSITION.TOP_CENTER
            })
            return
        }

        setProviderBtnLoad(true)

        const formData = new FormData()
        formData.append("file", providerApp)

        const response = await fetch(`${url.mainUrl}/admin/app?appType=provider`, {
            method: 'POST',
            headers: {
                "Authorization": admin.access_token
            },
            body: formData
        })

        if(!response.ok){
            const result = await response.json()
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER
            })

            setProviderBtnLoad(false)
            return
        }

        const result = await response.json()

        toast.success(result.message, {
            position: toast.POSITION.TOP_CENTER
        })

        getProviderApps()
        setProviderBtnLoad(false)

    }

    return (
        <section className='w-full h-full'>
            <div className='border h-full border-primary rounded-md flex flex-col'>
                <p className='text-primary font-bold text-xl py-2 text-center shadow-md shadow-primary'>Upload Apps</p>

                <div className='flex-1 flex p-2 gap-2'>

                    {/* Requestor apps */}
                    <div className='flex-1 border border-primary rounded-md'>
                        <p className='text-primary font-bold text-xl text-center shadow-md shadow-primary py-2'>Requestor App</p>

                        <input type="file" ref={requestorRef} onChange={handleRequstorApp} hidden/>

                        <div className='flex justify-evenly my-5'>

                            <button
                                type="submit"
                                onClick={() => requestorRef.current.click()}
                                className="w-[40%] block px-3 py-2 text-white bg-blue-500 focus:outline-none rounded-md"
                                >
                            Choose App
                            </button>

                            <input type="text" disabled placeholder={requestorApp.name} className='px-2 w-[40%] border-2 rounded-md border-gray-400'/>
                        </div>
                        {
                        requestorBtnLoad ?
                        <button
                          type="button"
                          className="w-1/2 block mx-auto my-3 px-3 py-2 text-white bg-blue-500 focus:outline-none rounded-md"
                          disabled
                        >
                          <div className="flex items-center justify-center">
                            <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                          </div>
                        </button>
                      : 
                        <button
                          type="submit"
                          onClick={() => addRequestorApp()}
                          className="w-1/2 block mx-auto my-3 px-3 py-2 text-white bg-blue-500 focus:outline-none rounded-md"
                        >
                          Upload App
                        </button>
                        }
                        
                        
                        <div className='flex flex-col gap-2 p-2 h-[300px] overflow-y-auto'>
                            {
                                requestorApps.length && 

                                requestorApps.map((app, index) => {
                                    return <p key={index * 300} className='text-white rounded-md mx-auto font-bold p-2 bg-primary'>{app}</p>
                                })
                            }
                        </div>

                        <div className='border-t-2 border-primary'>
                            <h1 className='font-bold text-center text-primary py-2 shadow-md shadow-primary'>Requester App Downloads</h1>
                            <table className='w-full'>
                                <thead>
                                    <tr className='border border-primary'>
                                        {generateDaysOfWeekBackwards(7).map(day => {
                                            return <th className='py-2' key={Math.random()}>{day}</th>
                                        })}
                                    </tr>
                                </thead>
                                {
                                    !downloads.length? ""
                                    :

                                    <tbody>
                                        <tr>
                                            {
                                                generateDaysOfWeekBackwards(7).map((day, index) => {
                                                    let numberOfDownloads;
                                                    for(let i = 0; i< downloads.length; i++){
                                                        if(downloads[i].dayOfWeek == day){
                                                            numberOfDownloads =  downloads[i].requestor.downloads
                                                        }
                                                    }

                                                    if(!numberOfDownloads){
                                                        return <td className='text-center'>0</td>
                                                    }

                                                    return <td  className='text-center'>{numberOfDownloads}</td>
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                }
                               
                            </table>
                        </div>
                    </div>

                    {/* Service provider apps */}

                    <div className='flex-1 border border-primary rounded-md'>
                        <p className='text-primary font-bold text-xl text-center shadow-md shadow-primary py-2'>Provider Apps</p>
                        
                        <input type="file" ref={providerRef} onChange={handleProvicerApp} hidden/>

                        <div className='flex justify-evenly my-5'>

                            <button
                                type="submit"
                                onClick={() => providerRef.current.click()}
                                className="w-[40%] block px-3 py-2 text-white bg-blue-500 focus:outline-none rounded-md"
                                >
                            Choose App
                            </button>

                            <input type="text" disabled placeholder={providerApp.name} className='px-2 w-[40%] border-2 rounded-md border-gray-400'/>
                        </div>
                        {
                        providerBtnLoad ?
                        <button
                          type="button"
                          className="w-1/2 block mx-auto my-3 px-3 py-2 text-white bg-blue-500 focus:outline-none rounded-md"
                          disabled
                        >
                          <div className="flex items-center justify-center">
                            <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                          </div>
                        </button>
                      : 
                        <button
                          type="submit"
                          onClick={() => addProviderApp()}
                          className="w-1/2 block mx-auto my-3 px-3 py-2 text-white bg-blue-500 focus:outline-none rounded-md"
                        >
                          Upload App
                        </button>
                        }

                        <div className='flex flex-col gap-2 p-2 h-[300px] overflow-y-auto'>
                            {
                                providerApps.length && 

                                providerApps.map((app, index) => {
                                    return <p key={index * 300} className='text-white rounded-md mx-auto font-bold p-2 bg-primary'>{app}</p>
                                })
                            }
                        </div>

                        <div className='border-t-2 border-primary'>
                            <h1 className='font-bold text-center text-primary py-2 shadow-md shadow-primary'>Provider App Downloads</h1>
                            <table className='w-full'>
                                <thead>
                                    <tr className='border border-primary'>
                                        {generateDaysOfWeekBackwards(7).map(day => {
                                            return <th className='py-2' key={Math.random()}>{day}</th>
                                        })}
                                    </tr>
                                </thead>
                                {
                                    !downloads.length? ""
                                    :

                                    <tbody>
                                        <tr>
                                            {
                                                generateDaysOfWeekBackwards(7).map((day, index) => {
                                                    let numberOfDownloads;
                                                    for(let i = 0; i< downloads.length; i++){
                                                        if(downloads[i].dayOfWeek == day){
                                                            numberOfDownloads =  downloads[i].provider.downloads
                                                        }
                                                    }

                                                    if(!numberOfDownloads){
                                                        return <td className='text-center'>0</td>
                                                    }

                                                    return <td  className='text-center'>{numberOfDownloads}</td>
                                                })
                                            }
                                        </tr>
                                    </tbody>
                                }
                               
                            </table>
                        </div>
                    </div>
                </div>
               
            </div>
        </section>
    )
}

function generateDaysOfWeekBackwards(numDays) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();
    const result = [];
  
    for (let i = 0; i < numDays; i++) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
  
      if (i === 0) {
        result.push('today');
      } else if (i === 1) {
        result.push('yesterday');
      } else {
        const dayName = daysOfWeek[day.getDay()];
        result.push(dayName);
      }
    }
  
    return result.reverse(); 
}

export default UploadApps