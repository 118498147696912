import React, { useEffect, useState, useMemo, useRef} from 'react'
import { useTable } from 'react-table';
import url from '../../baseUrl'
import { toast } from 'react-toastify';
import Papa from 'papaparse';

const Reports = () => {
    const [reports, setReports] = useState([])
    const [filtredReports, setFiltredReports] = useState([])
    const [filter, setFilter] = useState(false)
    const [companies, setCompanies] = useState([])
    const [params, setParams] = useState({
        company: "",
        to: "",
        from: ""
    })
    const startInputFilter = useRef(null)
    const endInputFilter = useRef(null)
    const statusRef = useRef()

    const columns = useMemo(
        () => [
          {
            Header: 'Transaction Id',
            accessor: 'query.payment.reff_id',
          },
          {
            Header: 'Requestor Details',
            accessor: 'query.requester.phone',
          },
          {
            Header: 'Provider Details',
            accessor: 'query.serviceProvider.phone',
          },
          {
            Header: 'Request Type',
            accessor: 'query.serviceType.type',
          },
          {
            Header: 'Service name',
            accessor: 'query.serviceType.name',
          },
          {
            Header: 'Partner Details',
            accessor: 'company.name',
          },
          {
            Header: 'Std amount charged',
            accessor: 'query.stdAmountCharged',
          },
          {
            Header: 'Hourly amount charged',
            accessor: 'query.overTime.amount',
          },
          {
            Header: 'Total Amount Charged',
            accessor: 'query.amount',
          },
          {
            Header: 'Partner Share',
            accessor: 'company.companyShare',
          },
          {
            Header: 'Requested Time',
            accessor: 'query.requestedTime',
          },
          {
            Header: 'Start Time',
            accessor: 'query.startedTime',
          },
          {
            Header: 'Completed Time',
            accessor: 'query.endedTime',
          },
          {
            Header: 'Location',
            accessor: 'query.location',
          },
          {
            Header: 'Status',
            accessor: 'query.status',
          },
        ],
        []
    );
    const admin = JSON.parse(localStorage.getItem('admin'))

    useEffect(()=>{
        getTransactionReports(params)
        getCompanies()
    }, [])

    async function getTransactionReports(params) {
        const admin = JSON.parse(localStorage.getItem('admin'));
        const queryParams = [];

        const selectElement = statusRef.current;

        // Check if an option is selected (optional)
        if (selectElement.selectedIndex !== 0) {
          // Unselect the option by setting selectedIndex to -1
          selectElement.selectedIndex = 0;
        }

        setFilter(false)
    
        if (params.company) {
            queryParams.push(`company=${params.company}`);
        }
    
        if (params.to) {
            queryParams.push(`to=${params.to}`);
        }
    
        if (params.from) {
            queryParams.push(`from=${params.from}`);
        }
    
        const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    
        const response = await fetch(`${url.mainUrl}/admin/reports/transaction${queryString}`, {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": admin.access_token
            }
        });
    
        if (!response.ok) {
            // Handle error here
            return;
        }
    
        const result = await response.json();

        for(let i = 0; i< result.length; i++){
          const item = result[i].query
          result[i].query.startedTime = `${item.startedTime ? formatISODate(item.startedTime).date : ""} ${item.startedTime? formatISODate(item.startedTime).time : ""}`
          result[i].query.endedTime = `${item.endedTime ? formatISODate(item.endedTime).date : ""} ${item.endedTime? formatISODate(item.endedTime).time : ""}`
          result[i].query.requestedTime = result[i].query.date || result[i].query.duration.from
          result[i].query.requestedTime && (result[i].query.requestedTime = `${formatISODate(result[i].query.requestedTime).date} ${formatISODate(result[i].query.requestedTime).time}`)
          result[i].query.duration && (result[i].query.duration.from = `${formatISODate(item.duration.from).date} ${formatISODate(item.duration.from).time}`)
          result[i].query.duration && (result[i].query.duration.to = `${formatISODate(item.duration.to).date} ${formatISODate(item.duration.to).time}`)
          result[i].query.stdAmountCharged = result[i].query.serviceType.price || result[i].query.serviceType.serviceFee
        }

        // Assuming you have a function setReports to handle the result
        setReports(result);
        setFiltredReports(result)

    }

    const handleParams = (e) => {
        const key = e.target.name
        const value = e.target.value

        if(key == "to" || key == "from"){
            const date = new Date(value)
            const values = params
            values[key] = date.toISOString()
            setParams({
                ...params,
                [key]: date.toISOString()
            })

            getTransactionReports(values)
            return

        }

        const values = params
        values[key] = value
        setParams({
            ...params,
            [key]: value
        })

        getTransactionReports(values)

    }

     // Create a table instance
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: filter? filtredReports : reports, // Pass the fetched data as the 'data' prop
    });


    function filterByStatus(status){
        setFilter(true)
        const filtred = reports.filter(report => report.query.status == status)
        setFiltredReports(filtred)
    }

    async function getCompanies(){
        const admin = JSON.parse(localStorage.getItem('admin'))
        const response = await fetch(`${url.mainUrl}/admin/company`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': admin.access_token
          }
        })
    
        if(!response.ok){
          return 
        }
    
        const result = await response.json()
        setCompanies(result.companies)
    }


    // convert to csv
    function convertToCSV() {
      const headers = [
        'Transaction Id',
        'Requestor Details',
        'Provider Details',
        'Request Type',
        'Service name',
        'Partner Details',
        'Std amount charged',
        'Hourly amount charged',
        'Total Amount Charged',
        'Partner Share',
        'Requested Time',
        'Start Time',
        'Completed Time',
        'Location',
        'Status',
      ];

      const rows = returnCleanedData(headers, filter? filtredReports : reports)
      const csvData = Papa.unparse(rows, {header: true}); // Assuming 'data' is an array of objects with headers
      return csvData;
    }

    // clean data to be generated
    function returnCleanedData(headers, data ){
      const csvData = []
      csvData.push(headers)
      data.forEach(function (item) {
        let row = [
            item.query.payment.reff_id || "", 
            item.query.requester.phone || "", 
            item.query.serviceProvider? item.query.serviceProvider.phone : "", 
            item.query.serviceType.type || "",
            item.query.serviceType.name || "",
            item.company.name || "", 
            item.query.serviceType.price || item.query.serviceType.serviceFee || "",
            item.query.overTime.amount || "",
            item.query.amount || "",
            item.query.companyshare || "",
            item.query.date || item.query.duration.from || "",
            item.query.startedTime || "",
            item.query.endedTime || "",
            item.query.location || "",
            item.query.status || ""
        ];
    
        csvData.push(row);
      })

      return csvData
    }

    // export csv
    function exportCSV() {
      const csvData = convertToCSV();
    
      // Create a Blob object and trigger a download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'transaction_reports.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
    

    return (
        <section className='h-full'>
        <div className='h-full border border-primary rounded-md flex flex-col'>
          <h1 className='text-xl font-bold text-primary py-2 text-center shadow-md'>Reports</h1>
          <div className='flex-1 flex flex-col'>
            <div className='p-3'>
                <div className='flex'>
                    <div className='flex-1 p-2'>
                            <label htmlFor="fromDate">From</label>
                            <input onChange={handleParams} ref={startInputFilter} name="from" type="date" htmlFor="fromDate" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"/>
                    </div>
                    <div className='flex-1 p-2'>
                            <label htmlFor="fromDate">To</label>
                            <input onChange={handleParams} ref={endInputFilter} name="to" type="date" htmlFor="fromDate" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"/>
                    </div>
                </div>
                <div className='flex gap-5'>
                    <div className='flex-1 max-w-[50%] mx-auto flex flex-col'>
                        <label htmlFor="service">Filter by Status</label>
                        <select
                            name="service"
                            id="service"
                            onClick={(e)=> filterByStatus(e.target.value)}
                            ref={statusRef}
                            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                            >
                            <option selected disabled>select status</option>
                            <option value={'placed'}>placed</option>
                            <option value={'processed'}>Processed</option>
                            <option value={'inprogress'}>inprogress</option>
                            <option value={'completed'}>completed</option>
                                    
                        </select>
                    </div>
                    {

                      admin.adminType == 'super'
                      
                      && 

                      <div className='flex-1 flex flex-col'>
                        <label htmlFor="company">Filter by company</label>
                        <select
                            name="company"
                            id="company"
                            onChange={handleParams}
                            className="w-full block mx-auto px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                        >
                            <option selected disabled>Choose Company</option>
                            {companies.length && companies.map((company) => {
                            return <option key={company._id} value={company._id} className='py-2'>
                                        {company.name}
                                    </option>
                                
                            })}
                        </select>
                    </div>
                    }
                    
                </div>
                
                  {/* Add the export button */}
                <div className='p-3 text-center'>
                  <button onClick={exportCSV} className='bg-primary text-white px-4 py-2 rounded-md'>Export to CSV</button>
                </div>
            </div>

            <div className='flex-1 flex overflow-y-auto'>
              {/* create the table here */}
              <div className='overflow-auto'>
                <table {...getTableProps()} className='table border border-collapse w-full'>
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()} className='bg-gray-200 p-2'>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} >
                          {row.cells.map((cell) => {
                            return <td {...cell.getCellProps()} className='border p-2'>{cell.render('Cell')}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    )
}

function formatISODate(isoString) {
  const date = new Date(isoString);

  // Check if the input ISO string is valid
  if (isNaN(date)) {
    return { date: "Invalid Date", time: "Invalid Time" };
  }

  // Subtract 3 hours from the date
  date.setHours(date.getHours() - 3);

  const optionsDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return { date: formattedDate, time: formattedTime };
}

export default Reports