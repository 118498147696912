import React, { useEffect, useState } from 'react'
import url from '../../baseUrl'
import { PropagateLoader } from 'react-spinners';

const Incidents = () => {
    const [incidents, setIncidents] = useState([])
    const [fetchedIncidents, setFetchedIncidents] = useState(false)

    useEffect(()=> {
        getAllIncidents()
    }, [])
    async function getAllIncidents(){
        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/incidents`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        })

        if(!response.ok){
            return 
        }

        const result = await response.json();
        setIncidents(result.incidents)
        setFetchedIncidents(true)

    }

    return (
        <section className='h-full p-2'>
            <div className='border border-primary rounded-md h-full flex flex-col'>
                <h1 className='shadow-md shadow-primary py-2 text-center text-xl font-bold text-primary'>All Incidents Reports</h1>
                <div className='flex-1 p-2 py-3 overflow-y-auto relative'>
                    {
                        !incidents.length? 
                            <div className='absolute left-[50%] top-[50%] -translate-x-[50%]'>
                                {
                                    fetchedIncidents? 
                                        <div className='text-gray-500 text-xl'> No Incidents Reports found</div>
                                    :
                                    <PropagateLoader color="#B7926B" loading={true} size={15} />
                                }
                            </div>
                        :

                        incidents.map(incident => {
                            return <div key={incident._id} className='flex max-h-[230px] border border-primary rounded-md p-2 text-primary'>
                                <div className='flex-1 grid grid-cols-2 gap-1 '>
                                    <p className='flex flex-col font-bold'>
                                        location
                                        <span className='font-[500] pl-2'>{incident.query.location}</span>
                                    </p>
                                    <p className='flex flex-col font-bold'>
                                        Servce name
                                        <span className='font-[500] pl-2'>{incident.query.serviceType.name}</span>
                                    </p>
                                    <p className='flex flex-col font-bold'>
                                        Reported Date
                                        <span className='font-[500] pl-2'>{formatISODate(incident.createdAt).date}</span>
                                    </p>
                                    <p className='flex flex-col font-bold'>
                                        Reported Time
                                        <span className='font-[500] pl-2'>{formatISODate(incident.createdAt).time}</span>
                                    </p>
                                    <p className='flex flex-col font-bold'>
                                        Name
                                        <span className='font-[500] pl-2'>{incident.serviceProvider.name}</span>
                                    </p>
                                </div>
                                <div className='w-[60%] flex flex-col'>
                                    <p className='font-bold'>Message</p>
                                    <p className='flex-1 border border-primary rounded-md p-2 overflow-y-auto' style={{ maxHeight: 'calc(100%)' }}>{incident.message}</p>
                                </div>
                            </div>
                        })
                    }

                </div>
            </div>
        </section>
  )
}

function formatISODate(isoString) {
    const date = new Date(isoString);
  
    // Check if the input ISO string is valid
    if (isNaN(date)) {
      return { date: "Invalid Date", time: "Invalid Time" };
    }
  
    const optionsDate = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };
  
    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
  
    return { date: formattedDate, time: formattedTime };
}

export default Incidents