import React, { useEffect, useState } from "react";
import url from "../../baseUrl";
import { CiCircleRemove } from "react-icons/ci";
import { toast } from "react-toastify";
import { PropagateLoader } from 'react-spinners';

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const [updateBtnLoad, setUpdateBtnLoad] = useState(false)
  const [addFaqSection, setAddFaqSection] = useState(false);
  const [updateFaqSection, setUpdateFaqSection] = useState(false);
  const [faq, setFaq] = useState({});
  const [activeFaq, setActiveFaq] = useState({})
  const [fetchedFaqs, setFetchedFaqs] = useState(false)

  useEffect(() => {
    getFaqs();
  }, []);

  const handleFaq = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setFaq({
      ...faq,
      [key]: value,
    });
  };

  const handleActiveFaq = (faq)=>{
    setActiveFaq(faq)
    setUpdateFaqSection(true)
  }


  async function getFaqs() {
    const admin = JSON.parse(localStorage.getItem("admin"));
    const response = await fetch(`${url.mainUrl}/faq`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
    });

    if (!response.ok) {
      return;
    }

    const result = await response.json();
    setFaqs(result.faqs);
    setFetchedFaqs(true)
  }

  async function addFaq(e) {
    e.preventDefault();

    setBtnLoad(true);
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/faq`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
      body: JSON.stringify(faq),
    });

    if (!response.ok) {
      const result = await response.json();

      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeButton: true,
      });

      setBtnLoad(false);
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      closeButton: true,
    });

    setBtnLoad(false);
    getFaqs()
    setAddFaqSection(false)
    setFaq({})
  }

  async function updateFaq(){

    setUpdateBtnLoad(true);
    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/faq/${activeFaq._id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      },
      body: JSON.stringify(faq),
    });

    if (!response.ok) {
      const result = await response.json();

      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeButton: true,
      });

      setUpdateBtnLoad(false);
      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      closeButton: true,
    });

    setUpdateBtnLoad(false);
    getFaqs()
    setUpdateFaqSection(false)
    setFaq({})
  }

  async function deleteFaq(_id){

    const admin = JSON.parse(localStorage.getItem("admin"));

    const response = await fetch(`${url.mainUrl}/admin/faq/${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: admin.access_token,
      }
    });

    if (!response.ok) {
      const result = await response.json();

      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        closeButton: true,
      });

      return;
    }

    const result = await response.json();
    toast.success(result.message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      closeButton: true,
    });

    getFaqs()
    setUpdateFaqSection(false)
  }

  const handleConfirmUpdate = (e)=>{
    e.preventDefault()
    
    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>Are You Sure you want to update Faq?</p>
        <div className='flex justify-evenly mt-3'>
            <button onClick={() => {updateFaq(); closeToast();}} className='py-1 px-3 bg-green-500 rounded-md text-white'>Accept</button>
            <button className='py-1 px-3 bg-danger rounded-md text-white'>Decline</button>
        </div>
        
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  }

  const handleConfirmDelete = (_id)=>{
    
    const closeToast = () => toast.dismiss();
    toast.warn(
      <div>
        <p>Are You Sure you want to delete Faq?</p>
        <div className='flex justify-evenly mt-3'>
            <button onClick={() => {deleteFaq(_id); closeToast();}} className='py-1 px-3 bg-green-500 rounded-md text-white'>Accept</button>
            <button className='py-1 px-3 bg-danger rounded-md text-white'>Decline</button>
        </div>
        
      </div>,
      {
        position: "top-center",
        autoClose: 5000, // Keep the toast open until an action is taken
        closeButton: true, // Hide the default close button
      }
    );
  }

  return (
    <section className="h-full w-full flex flex-col relative">
      <div className="flex-1 p-2 m-4 border-primary rounded-md">
        <h1 className="font-bold p-2 text-primary text-xl text-center shadow-md shadow-primary">
          FAQs
        </h1>
        <div className="p-2">
          <ol className=" list-decimal m-2 p-4 flex flex-col gap-2">
            {!faqs.length? 
                <div className='absolute left-[50%] top-[50%] -translate-x-[50%]'>
                {
                    fetchedFaqs? 
                        <div className='text-gray-500 text-xl'> No Faqs Found</div>
                    :
                    <PropagateLoader color="#B7926B" loading={true} size={15} />
                }
              </div>
              :
              faqs.map((faq) => {
                return (
                  <div className=" border border-primary py-3 rounded-md px-8 flex items-center justify-between">
                    <div>
                      <li className="font-bold capitalize py-2">
                        {faq.question}
                      </li>
                      <ul className="pl-2 list-disc">
                        <li className="capitalize">{faq.answer}</li>
                      </ul>
                    </div>
                    <div>
                      <button
                        type="button"
                        id={faq._id}
                        onClick={() => handleActiveFaq(faq)}
                        className="py-1 mx-2 px-3 bg-green-300 rounded-md hover:bg-success focus:bg-success"
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        id={faq._id}
                        onClick={() => handleConfirmDelete(faq._id)}
                        className="py-1 px-3 bg-red-300 rounded-md hover:bg-danger focus:bg-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
          </ol>
        </div>
      </div>
      {
        <>
          {/* Add Faq */}
          <div
            className={`${
              !addFaqSection && "hidden"
            } absolute h-full w-full top-0 bg-white p-2 border border-primary rounded-md`}
          >
            <span
              onClick={() => setAddFaqSection(false)}
              className="text-red-300 text-6xl cursor-pointer rounded-full block mx-auto text-center m-auto w-max"
            >
              <CiCircleRemove />
            </span>
            <h1 className="py-2 border border-primary text-primary rounded-md font-bold text-xl text-center shadow-md shadow-primary">
              Add FAQ
            </h1>
            <div className="p-3">
              <form action="" onSubmit={addFaq} className="flex flex-col gap-5">
                <input
                  onChange={handleFaq}
                  type="question"
                  name="question"
                  id="question"
                  placeholder="Question"
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                />
                <textarea
                  onChange={handleFaq}
                  name="answer"
                  id="answer"
                  placeholder="Answer"
                  className="w-full px-3 py-2 h-[500px] resize-none placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                ></textarea>

                {btnLoad ? (
                  <button
                    type="button"
                    className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                    disabled
                  >
                    <div className="flex items-center justify-center">
                      <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                    </div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                  >
                    Submit Faq
                  </button>
                )}
              </form>
            </div>
          </div>

          {/* update section */}
          {
            !Object.keys(activeFaq).length? "" : 

            <div className={`${!updateFaqSection && "hidden"} absolute h-full w-full top-0 bg-white p-2 border border-primary rounded-md`}>
            <span
              onClick={() => {setUpdateFaqSection(false); setActiveFaq({})}}
              className="text-red-300 text-6xl cursor-pointer rounded-full block mx-auto text-center m-auto w-max"
            >
              <CiCircleRemove />
            </span>
            <h1 className="py-2 border border-primary text-primary rounded-md font-bold text-xl text-center shadow-md shadow-primary">
              Update FAQ
            </h1>
            <div className="p-3">
              <form action="" onSubmit={handleConfirmUpdate} className="flex flex-col gap-5">
                <input
                  onChange={handleFaq}
                  type="question"
                  name="question"
                  id="question"
                  placeholder="Question"
                  defaultValue={activeFaq.question}
                  className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                />
                <textarea
                  onChange={handleFaq}
                  name="answer"
                  id="answer"
                  placeholder="Answer"
                  defaultValue={activeFaq.answer}
                  className="w-full px-3 py-2 h-[500px] resize-none placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                ></textarea>

                {updateBtnLoad ? (
                  <button
                    type="button"
                    className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                    disabled
                  >
                    <div className="flex items-center justify-center">
                      <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                    </div>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                  >
                    Update
                  </button>
                )}
              </form>
            </div>
          </div>
          }
          
        </>
      }

      <div className="p-4">
        <button
          type="button"
          onClick={() => setAddFaqSection(true)}
          className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
        >
          Add Faq
        </button>
      </div>
    </section>
  );
};

export default Faqs;
