import React, { useState } from 'react'
import { toast } from 'react-toastify'
import url from '../baseUrl'
import { useNavigate } from 'react-router-dom'
import getAdminDetails from '../utilities/getAdminDetails';

const ForgotPassword = () => {
  const navigate = useNavigate()
  const [passwordDetails, setPasswordDetails] = useState({
    verificationMode: "forgotPassword"
  })
  const [btnLoad, setBtnLoad] = useState(false)
  const [emailBtnLoad, setEmailBtnLoad] = useState(false)
  const [email, setEmail] = useState('')
  const [resetSection, setResetSection] = useState(false)

  const handlePasswordDetails = (e)=>{
    const key = e.target.name
    const value = e.target.value.trim()

    setPasswordDetails({
      ...passwordDetails,
      [key]: value
    })
  }

  const resetPassword = async (e)=> {
    e.preventDefault()

    const validatedFields = validateFields()
    if(!validatedFields){
      return
    }

    setBtnLoad(true)
    const response = await fetch(`${url.mainUrl}/verifyotp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(passwordDetails)
    })

    if(!response.ok){
      const result = await response.json()
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      setBtnLoad(false)
      return
    }

    const result = await response.json()
    toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
    })

    logIn({
      email_id_phone: passwordDetails.email,
      password: passwordDetails.newPassword
    })

  }

  // validate fields
  function validateFields(){
    if(!passwordDetails.email){
      toast.error("Email is required", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      return false
    }

    if(!passwordDetails.otp){
      toast.error("Otp is required", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      return false
    }

    if(!passwordDetails.newPassword){
      toast.error("New Password is required", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      return false
    }

    if(!passwordDetails.confirmPassword){
      toast.error("Confirm Password is required", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      return false
    }

    if(passwordDetails.newPassword !== passwordDetails.confirmPassword){
      toast.error("Passwords do not match", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      return false
    }

    return true

  }


  const requestOtp = async (e)=>{
    e.preventDefault()

    // check if email is available
    if(!email){
      toast.error("Email is required", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      return
    }

    setEmailBtnLoad(true)
    // request otp
    const response = await fetch(`${url.mainUrl}/forgotpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email})
    })

    if(!response.ok){
      const result = await response.json()
      toast.error(result.error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
      })

      setEmailBtnLoad(false)
      return
    }

    const result = await response.json()
    toast.success(result.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000
    })

    setEmailBtnLoad(false)
    setResetSection(true)
    setPasswordDetails({
      ...passwordDetails,
      email: email
    })

  }

  const logIn = async (data)=>{
 
    setBtnLoad(true)
    const response = await fetch(`${url.mainUrl}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })

    // check if the response is not okey
    if(!response.ok){
        const result = await response.json()
        setBtnLoad(false)
        toast.error(result.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // milliseconds
        });
        return
    }

    const result = await response.json()

    // check if the roles include admin
    if(!result.roles.includes('admin')){
        toast.error("User not found", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // milliseconds
        });
        setBtnLoad(false)
        return
    }

    localStorage.setItem('admin', JSON.stringify(result));
    localStorage.setItem('refreshTime', new Date());
    setBtnLoad(false)

    // get admin details
    const adminDetails = await getAdminDetails(result.access_token)
    if(adminDetails.message){
        localStorage.setItem('adminDetails', JSON.stringify(adminDetails.details));
    }
    navigate('/', {replace: true})

  }

  return (
    <div className="flex items-center min-h-screen bg-white">
      <div className="container mx-auto relative">
          <div className={`max-w-md mx-auto my-1 ${resetSection && "hidden"}`}>
              <div className="text-center">
                  <h1 className="my-3 text-3xl font-semibold text-primary">Enter Your email address</h1>
              </div>
              <div className="m-7">
                  <form action="" onSubmit={requestOtp}>
                      <div className="mb-6">
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email Address"
                            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                        />
                      </div>
                      <div className="mb-6">
                          {
                              emailBtnLoad? <button
                              type="button"
                              className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                              disabled
                          >
                              <div className="flex items-center justify-center">
                                  <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                              </div>
                          </button> :  <button
                              type="submit"
                              className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                          >
                            Request OTP
                          </button>
                          }
                          
                      </div>
                  </form>
              </div>
          </div>
          <div className={`max-w-md mx-auto my-10 ${!resetSection && "hidden"}`}>
              <div className="text-center">
                  <h1 className="my-3 text-3xl font-semibold text-primary">Reset Password</h1>
              </div>
              <div className="m-7">
                  <form action="" onSubmit={resetPassword}>
                      <div className={`mb-6 ${email && "hidden"}`}>
                        <input
                            onChange={handlePasswordDetails}
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email Address"
                            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                        />
                      </div>
                      <div className="mb-6">
                        <input
                            onChange={handlePasswordDetails}
                            type="number"
                            name="otp"
                            id="otp"
                            placeholder="otp"
                            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                        />
                      </div>
                      <div className="mb-6">
                        <input
                            onChange={handlePasswordDetails}
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            placeholder="New Password"
                            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                        />
                      </div>
                      <div className="mb-6">
                        <input
                            onChange={handlePasswordDetails}
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                        />
                      </div>
                      <div className="mb-6">
                          {
                              btnLoad? <button
                              type="button"
                              className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                              disabled
                          >
                              <div className="flex items-center justify-center">
                                  <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                              </div>
                          </button> :  <button
                              type="submit"
                              className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                          >
                          Reset Password
                          </button>
                          }
                          
                      </div>
                  </form>
              </div>    
          </div>
          
      </div>
    </div>
  )
}

export default ForgotPassword