import React, { useEffect, useState } from 'react'
import providers from '../../utilities/getServiceProviders'
import {CiCircleRemove} from 'react-icons/ci'
import url from '../../baseUrl'
import { toast } from 'react-toastify'
import { PropagateLoader } from 'react-spinners';

const ServiceProviders = () => {
    const [serviceProviders, setServiceProviders] = useState({})
    const [providersLoaded, setProvidersLoaded] = useState(false)
    const [btnLoad, setBtnload] = useState(false)
    const [addProviderSection, setAddproviderSection] = useState(false)
    const [providerDetails, setProviderDetails] = useState({})
    const [services, setServices] = useState({})
    const [companies, setCompanies] = useState({})
    const [activeCompany, setActiveCompany] = useState({})
    const adminType = JSON.parse(localStorage.getItem('admin')).adminType
    const adminDetails = JSON.parse(localStorage.getItem('adminDetails'))
    const [activeProvider, setActiveProvider] = useState({});
    const [activeService, setActiveService] = useState("")

    const handleProviderDetails = (e)=>{
        const key = e.target.name
        const value = e.target.value

        setProviderDetails({
            ...providerDetails,
            [key]: value
        })
    }

    console.log(providerDetails)
    useEffect(()=>{
        getCompanies()
        getServices()
        getServiceProviders()
    }, [])

    const handleActiveCompany = (e) => {
        const value = e.target.value;
        setActiveCompany(value);
        setServiceProviders({});
        console.log(value)
        getServiceProviders(value)
    };

    const handleActiveProvider = (providerId) => {
        setActiveProvider(()=> {
            const singleProvider = serviceProviders.filter((provider)=>{
                return provider._id == providerId
            })

            return singleProvider[0];
        })
    }

    // getCompanies
    async function getCompanies() {
        const admin = JSON.parse(localStorage.getItem("admin"));
        const response = await fetch(`${url.mainUrl}/admin/company`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: admin.access_token,
        },
        });

        if (!response.ok) {
        return;
        }

        const result = await response.json();
        setCompanies(result.companies);
    }

    // get service providers
    async function getServiceProviders (company){
        setProvidersLoaded(false)
        const service_providers = await providers(company)
        if(service_providers.message){
            setServiceProviders(service_providers.serviceProviders)
            setProvidersLoaded(true)
        }
    }

    // get all services
    async function getServices(){
        const admin = JSON.parse(localStorage.getItem('admin'));
    
        const response = await fetch(`${url.mainUrl}/admin/category`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': admin.access_token
          }
        })
    
        if(!response.ok){
          return 
        }
    
        const result = await response.json()
        console.log("result", result)
    
        setServices(result.categories) 
    }
    
    async function addServiceProvider(e){
        e.preventDefault()
        setBtnload(true)

        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/serviceprovider`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            },
            body: JSON.stringify(providerDetails)
        })

        if(!response.ok){
            const result = await response.json()
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: true
            })

            setBtnload(false)
            return
        }

        const result = await response.json()
            toast.success(result.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: true
        })

        setAddproviderSection(false)
        getServiceProviders()
        setBtnload(false)

    }

    async function addServiceToProvider(){
        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/serviceprovider/service`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            },
            body: JSON.stringify({
                serviceProvider: activeProvider._id,
                service: activeService
            })
        })

        if(!response.ok){
            const result = await response.json()
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: true
            })

            return
        }

        const result = await response.json()
            toast.success(result.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: true
        })

        setActiveProvider({})
        setActiveService({})
        getServiceProviders()

    }
    async function removeSeriviceFromProvider(service){
        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/serviceprovider/service`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            },
            body: JSON.stringify({
                serviceProvider: activeProvider._id,
                service
            })
        })

        if(!response.ok){
            const result = await response.json()
            toast.error(result.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: true
            })

            return
        }

        const result = await response.json()
            toast.success(result.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: true
        })

        setActiveProvider({})
        getServiceProviders()

    }

    return (
        <section className='h-full flex flex-col p-2 gap-2 relative'>
            <div className='border border-primary rounded-md flex-1'>
                <h1 className='font-bold text-xl py-2 px-3 text-primary shadow-md text-center'>Service Providers Available</h1>
                {adminType == "super" && (
                    <div className='py-3'>
                        <select
                        name="service"
                        id="service"
                        onChange={handleActiveCompany}
                        className="w-1/2 block mx-auto px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                        >
                        <option selected value={""}>
                            All
                        </option>
                        {companies.length &&
                            companies.map((company) => {
                            return (
                                <option
                                key={company._id}
                                value={company._id}
                                className="py-2"
                                >
                                {company.name}
                                </option>
                            );
                            })}
                        </select>
                    </div>
                )}
                <div className='p-2 flex flex-col gap-2 h-[700px] w-full overflow-auto'>
                    {
                        !serviceProviders.length?

                        !providersLoaded?
                        <div className='absolute left-[50%] top-[50%] translate-x-[50%]'>
                            <PropagateLoader color="#B7926B" loading={true} size={15} />
                        </div>

                        :

                        <div className='absolute left-[50%] top-[50%] -translate-x-[50%]'>
                            <p className='text-gray-500 text-xl'>No Service Providers Found</p>
                         </div>

                        : 
                        
                        serviceProviders.map((provider) => {
                           return <div className='flex items-center justify-evenly text-primary font-bold border border-primary py-2 rounded-md '>
                                <p className='flex-1 gap-2 flex flex-col text-center'>
                                    Name
                                    <span className='font-[500] capitalize'>{provider.firstName + " " + provider.lastName}</span>
                                </p>
                                <p className='flex-1 gap-2 flex flex-col text-center'>
                                    Email
                                    <span className='font-[500] capitalize'>{provider.email}</span>
                                </p>
                                <p className='flex-1 gap-2 flex flex-col text-center'>
                                    Services
                                    <span className='font-[500] capitalize'>{provider.services.length? provider.services[0].category.name : ""}</span>
                                </p>
                                <p className='flex-1 gap-2 flex flex-col text-center'>
                                    Phone
                                    <span className='font-[500] capitalize'>{provider.phone}</span>
                                </p>
                                <p className='flex-1 gap-2 flex flex-col text-center'>
                                    Id/passport
                                    <span className='font-[500] capitalize'>{provider.identity}</span>
                                </p>
                                <button onClick = {() => handleActiveProvider(provider._id)} className='bg-primary w-48 rounded-md cursor-pointer py-2 text-white px-3 mx-5 block'>More</button>
                            </div>
                        })
                    }
                   
                </div>

            </div>

                    {/* view more details of service provider */}
            <div className={`${!Object.keys(activeProvider).length && "hidden"} absolute h-full w-[95%] left-0 right-0 m-auto bg-white text-primary`}>
                <span onClick={() => setActiveProvider({})} className='text-red-300 text-6xl cursor-pointer rounded-full inline-block text-center absolute left-0 right-0 m-auto w-max'>
                    <CiCircleRemove />
                </span>
                <h1 className='text-primary font-bold text-center underline text-xl mt-16'>Service provider Details</h1>
                {
                    Object.keys(activeProvider).length && 
                    <div className='flex flex-col items-center justify-center'>
                        <div className='flex items-center justify-evenly py-2 w-1/2'>
                            <p className='font-bold flex-1'>First Name</p>
                            <p className='flex-1'>{activeProvider.firstName}</p>
                        </div> 
                        <div className='flex items-center justify-evenly py-2 w-1/2'>
                            <p className='font-bold flex-1 '>Last Name</p>
                            <p className='flex-1'>{activeProvider.lastName}</p>
                        </div>
                        <div className='flex items-center justify-evenly py-2 w-1/2'>
                            <p className='font-bold flex-1 '>Email</p>
                            <p className='flex-1'>{activeProvider.email}</p>
                        </div>
                        <div className='flex items-center justify-evenly py-2 w-1/2'>
                            <p className='font-bold flex-1 '>Phone</p>
                            <p className='flex-1'>{activeProvider.phone}</p>
                        </div>
                        <div className='flex items-center justify-evenly py-2 w-1/2'>
                            <p className='font-bold flex-1 '>Id Number</p>
                            <p className='flex-1'>{activeProvider.identity}</p>
                        </div>
                    </div>
                }
               
                <h1 className='text-center font-bold underline'>Services</h1>
                {
                    Object.keys(activeProvider).length && 
                    <div>
                    {
                        Object.keys(activeProvider).length && activeProvider.services.map(service => {
                            return <div className='flex items-center justify-evenly py-2'>
                                <p className='font-bold'>{service.category.name}</p>
                                <button onClick={() => removeSeriviceFromProvider(service.category._id)} className='bg-primary w-48 rounded-md cursor-pointer py-2 text-white px-3 mx-5 block'>Remove Service</button>
                            </div>
                        })
                    }

                        <div className='flex items-center justify-center gap-8 mt-10'>
                            <select
                                name="service"
                                id="service"
                                onChange={(e)=> setActiveService(e.target.value)}
                                className="w-[300px] px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                            >
                                <option selected disabled>Choose Service</option>
                                {services.length && services.map((service) => {
                                    if(adminDetails.company.services.includes(service._id) && activeProvider.services.some(providerService => providerService.category._id != service._id)){
                                        return <option key={service._id} value={service._id} className='py-2'>
                                            {service.name}
                                        </option>
                                    } 
                                })}
                            </select>
                            <button onClick={() => addServiceToProvider()} className='bg-primary w-48 rounded-md cursor-pointer py-2 text-white px-3 mx-5 block'>Add Service</button>
                        </div>

                    </div>
                }
               
            </div>

            {/* Add Service provider */}
            <div className={`absolute w-full p-5 h-full bg-white ${addProviderSection ? "scale-100": 'scale-0'} transition-all`}>
                <span onClick={() => setAddproviderSection(false)} className='text-red-300 text-6xl cursor-pointer rounded-full inline-block text-center absolute left-0 right-0 m-auto w-max'>
                    <CiCircleRemove />
                </span>
                <h1 className='text-primary font-bold py-2 text-center text-xl mt-14'>Add Service provider</h1>
                <form action="" onSubmit={addServiceProvider} className='px-2 mt-2 flex-1 flex flex-col justify-between' >
                    <div className="mb-6 flex flex-col gap-5 py-3">
                        
                    <select
                        name="service"
                        id="service"
                        onChange={handleProviderDetails}
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700"
                    >
                        <option selected disabled>Choose Service</option>
                        {services.length && services.map((service) => {
                            if(adminDetails.company.services.includes(service._id)){
                                return <option key={service._id} value={service._id} className='py-2'>
                                    {service.name}
                                </option>
                            } 
                        })}
                    </select>

                    <input
                        onChange={handleProviderDetails}
                        type="firstName"
                        name="firstName"
                        id="lastName"
                        placeholder="Last Name"
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                    />
                    <input
                        onChange={handleProviderDetails}
                        type="lastName"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                    />
                    <input
                        onChange={handleProviderDetails}
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                    />
                    {/* <div
                    ref={divRef}
                    className='flex items-center gap-3 w-full pl-3 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary text-gray-700'
                    tabIndex={1}
                    >
                    <p>254</p>
                    <input
                        onClick={handleInputClick}
                        onChange={handleAdminDetails}
                        type="phone"
                        name="phone"
                        id="phone"
                        placeholder="Phone Number"
                        className='flex-1 py-2 px-3 outline-none'
                    />
                    </div> */}

                    <input
                        onChange={handleProviderDetails}
                        type="phone"
                        name="phone"
                        id="phone"
                        placeholder="Phone Number"
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                    />
                    <input
                        onChange={handleProviderDetails}
                        type="identity"
                        name="identity"
                        id="identity"
                        placeholder="Id or Passport"
                        className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-primary focus:border-ring-primary  text-gray-700"
                    />
                    </div>
                    <div className="mb-6">
                        {
                            btnLoad? <button
                            type="button"
                            className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                            disabled
                        >
                            <div className="flex items-center justify-center">
                                <div className="h-6 w-6 border-t-transparent border-solid animate-spin rounded-full border-white border-4"></div>
                            </div>
                        </button> :  <button
                            type="submit"
                            className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
                        >
                        Submit
                        </button>
                        }
                        
                    </div>
                </form>
            </div>
            <button
            onClick={()=> setAddproviderSection(true)}
            type="button"
            className="w-full px-3 py-4 text-white bg-primary focus:outline-none rounded-md"
            >
                Add Service Provider
            </button>
            
        </section>

    )
}

export default ServiceProviders