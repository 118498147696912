import React, {useEffect, useState} from 'react'
// import getAdminDetails from '../../utilities/getAdminDetails';
// import { useNavigate } from 'react-router-dom';

import {FaCircleUser} from 'react-icons/fa6'
import {BsBellFill} from 'react-icons/bs'

const Header = ({unreadNotifications, active, handleActivePage}) => {
    const [search, setSearch] = useState('');
    const [adminDetails, setAdminDetails] = useState({})
    // const navigate = useNavigate()

    useEffect(()=>{
        let details = localStorage.getItem('adminDetails');
        // console.log(details)
        // if(!details){
        //     const admin = JSON.parse(localStorage.getItem('admin'));

        //     details = await getAdminDetails(admin.access_token)
        //     if(!details.message){
        //         navigate('/login')
        //         return
        //     }

        //     localStorage.setItem('adminDetails', JSON.stringify(details))

        // }

        setAdminDetails(JSON.parse(details));
    }, [])

    return (
        <div className='bg-primary flex py-2 items-center justify-between px-5 lg:px-10'>
            <div className='flex items-center justify-center'>
                <div className="flex items-center max-w-xl mx-auto bg-white op rounded-lg">
                    <div className="w-full">
                    <input
                        type="search"
                        className="w-full px-4 py-1 text-gray-800 rounded-full focus:outline-none"
                        placeholder="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    </div>
                    <div>
                    <button
                        type="submit"
                        className={`flex items-center justify-center w-12 h-12 text-white rounded-r-lg ${
                        search.length > 0 ? 'bg-gray-500' : ' bg-black opacity-30 cursor-not-allowed'
                        }`}
                        disabled={search.length === 0}
                    >
                        <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                        </svg>
                    </button>
                    </div>
                </div>
            </div>
            <div className='flex gap-5 items-center'>
                <p className='font-[500] text-white'>Welcome back 
                    <span className='px-2 font-bold capitalize text-xl'>{Object.keys(adminDetails).length && adminDetails.company.name}</span>
                </p>
                <div onClick={()=> handleActivePage('notifications')} className={`relative text-3xl ${active == 'notifications'? "text-primary bg-white rounded-full": "text-white"} p-2 cursor-pointer`}>
                    <BsBellFill/>
                    <span className={`absolute text-sm top-0.5 right-0.5 bg-green-500 w-5 h-5 text-white rounded-full ${unreadNotifications? "grid": "hidden"} place-items-center`}>{unreadNotifications}</span>
                </div>
                <div className='cursor-pointer'>
                    <span className='text-white text-5xl'>
                        <FaCircleUser />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Header