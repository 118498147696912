import React from 'react'
import { FaBed } from "react-icons/fa6";

const SideBar = ({handleActiveSection, activeSection}) => {
  return (
    <div className='border-2 border-t-gray-300 w-full h-full flex-1 rounded-md flex flex-col gap-1 py-2 px-1 bg-primary'>
        <div onClick={()=> handleActiveSection('categories')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${activeSection == "categories"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
            <span>
                <FaBed />
            </span>
            <span>
                House Categories
            </span>
        </div>
        <div onClick={()=> handleActiveSection('houselocations')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${activeSection == "houselocations"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
            <span>
                <FaBed />
            </span>
            <span>
                House Locations
            </span>
        </div>
        <div onClick={()=> handleActiveSection('properties')} className={`flex rounded-md px-7 gap-5 py-2 items-center text-xl ${activeSection == "properties"? "bg-white text-primary": "text-gray-300 hover:text-white"} cursor-pointer`}>
            <span>
                <FaBed />
            </span>
            <span>
                Properties
            </span>
        </div>
    </div>
  )
}

export default SideBar