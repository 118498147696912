import React, { useEffect, useState } from 'react'
import url from '../../baseUrl'

const SitInQueries = () => {
  const [sitIns, setSitIns] = useState([])

  useEffect(()=>{
    getAllSitInQueries()
  }, [])

  async function getAllSitInQueries(){
    const admin = JSON.parse(localStorage.getItem('admin'))

    const response = await fetch(`${url.mainUrl}/admin/query/sitin`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': admin.access_token
      }
    })

    if(!response.ok){
      return
    }

    const result = await response.json()
    setSitIns(result)
  }

  return (
    <section className='h-full p-2'>
      <div className='border border-primary rounded-md h-full flex flex-col'>
        <h1 className='text-primary font-bold text-xl text-center py-2 shadow-md shadow-primary'>Sit In Queries</h1>
        <div className='flex-1 p-2 overflow-y-auto flex flex-col gap-2'>
            {
              !sitIns.length? "" : 

              sitIns.map(sitIn => {

                return <div className='flex p-2 border border-primary rounded-md'>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Name

                  <span className='font-[500] pt-2 text-center'>{sitIn.requester.firstName + " " + sitIn.requester.lastName}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Phone

                  <span className='font-[500] pt-2 text-center'>{formatPhone(sitIn.requester.phone)}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Sit In Category

                  <span className='font-[500] pt-2 text-center'>{sitIn.serviceType.name}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Location

                  <span className='font-[500] pt-2'>{sitIn.location}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  House Size

                  <span className='font-[500] pt-2'>{sitIn.houseSize}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Number of children

                  <span className='font-[500] pt-2'>{sitIn.numberOfChildren}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Age of Children

                  <span className='font-[500] pt-2'>{joinChildrenAges(sitIn.childrenAge)}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Service Date

                  <span className='font-[500] pt-2'>{formatISODate(sitIn.date, true).date}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Requested Date

                  <span className='font-[500] pt-2'>{formatISODate(sitIn.createdAt, true).date + " " + formatISODate(sitIn.createdAt, true).time}</span>
                </p>
                <p className='flex-1 text-center flex flex-col font-bold text-primary p-2'>
                  Status

                  <span className='font-[500] pt-2'>{sitIn.status}</span>
                </p>
            </div>
              })
              
            }
           
        </div>
      </div>
    </section>
  )
}

function formatISODate(isoString, createdAt) {
  const date = new Date(isoString); 

  // Check if the input ISO string is valid
  if (isNaN(date)) {
    return { date: "Invalid Date", time: "Invalid Time" };
  }

  if(createdAt){
    date.setHours(date.getHours() + 3)
  }
  // Subtract 3 hours from the date
  date.setHours(date.getHours() - 3);

  const optionsDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const optionsTime = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return { date: formattedDate, time: formattedTime };
}

function formatPhone(phone){
  const formatedPhone = phone.slice(3)

  return 0 + formatedPhone
}

function joinChildrenAges(ages) {
  let output = "";

  for (let index = 0; index < ages.length; index++) {
      let newAge = ages[index].toString();

      if (index === ages.length - 1) {
          output += newAge + " ";
      } else {
          output += newAge + ", ";
      }
  }

  return output;
}

export default SitInQueries