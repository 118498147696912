import React, { useEffect, useState } from 'react'
import {FaCodePullRequest} from 'react-icons/fa6'
import url from '../../baseUrl'
import Providers from '../../utilities/getServiceProviders'
import BarChart from './dashboardComponents/BarChart'
import LineChart from './dashboardComponents/LineChart'

const Dashboard = () => {
    const [serviceProviders, setServiceProviders] = useState([])
    const [payments, setPayments] = useState([])
    const [requesters, setRequesters] = useState([])
    const [queries, setQueries] = useState([])
    const [adminType, setAdminType] = useState('')

    useEffect(()=>{
        getDashboardDetails()
        const admin = JSON.parse(localStorage.getItem('admin'))
        setAdminType(admin.adminType)
    }, [])

    async function getDashboardDetails(){
        await getServiceProviders()
        await getAllPayments()
        await getAllRequesters()
        await getAllQueries()
    }
    // get service providers
    async function getServiceProviders(){
        const service_providers = await Providers()
        if(service_providers.message){
        setServiceProviders(service_providers.serviceProviders)
        }
    }

    // get all payments
    async function getAllPayments(){
        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/payments`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        })

        if(!response.ok){
            return
        }

        const result = await response.json()
        setPayments(result.payments)

    }

    // get all requesters
    async function getAllRequesters(){
        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/requesters`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        })

        if(!response.ok){
            return
        }

        const result = await response.json()
        setRequesters(result.requesters)
    }

    // get all queries
    async function getAllQueries(){
        const admin = JSON.parse(localStorage.getItem('admin'));
        const response = await fetch(`${url.mainUrl}/admin/query`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': admin.access_token
            }
        })

        if(!response.ok){
            return
        }

        const result = await response.json()
        setQueries(result.queries)
    }



    return (
        <section className='flex flex-col gap-10'>
            <div className='py-5 flex items-center justify-evenly'>
                {
                    adminType && adminType == "super" &&

                    <>
                        <div className='w-[20%] h-[150px] shadow-md shadow-primary rounded-md flex flex-col items-center justify-center text-primary'>
                            <div>
                                <span className='inline-block text-primary p-3 border-2 font-bold text-xl border-gray-400 rounded-full'>
                                    <FaCodePullRequest />
                                </span>
                            </div>
                             <p className='flex flex-col justify-center items-center font-bold '>All Requestors 
                                <span>{requesters.length}</span>
                            </p>
                        </div>
                        <div className='w-[20%] h-[150px] shadow-md shadow-primary rounded-md flex flex-col items-center justify-center text-primary'>
                            <div>
                                <span className='inline-block text-primary p-3 border-2 font-bold text-xl border-gray-400 rounded-full'>
                                    <FaCodePullRequest />
                                </span>
                            </div>
                            <p className='flex flex-col justify-center items-center font-bold '>All Transactions 
                                <span>{payments.length}</span>
                            </p>
                        </div>
                    
                    </>
                }
               
                <div className='w-[20%] h-[150px] shadow-md shadow-primary rounded-md flex flex-col items-center justify-center text-primary'>
                    <div>
                        <span className='inline-block text-primary p-3 border-2 font-bold text-xl border-gray-400 rounded-full'>
                            <FaCodePullRequest />
                        </span>
                    </div>
                    <p className='flex flex-col justify-center items-center font-bold '>All Providers 
                        <span>{serviceProviders.length}</span>
                    </p>
                </div>
            </div>
            
            <div className='flex-1 flex px-5 flex-wrap'>
                <div className='w-1/2'>
                    <LineChart queries={queries} />
                </div>
                <div className='w-1/2'>
                    <BarChart queries={queries} />
                </div>
            </div>
        </section>
  )
}

export default Dashboard