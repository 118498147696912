import React, { useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto'; // Import Chart.js

const BarChart = ({queries}) => {
    const dataToUse = returnPastSevenDays(queries)
    const days = dataToUse.map(data => data.dayOfWeek)
    const requests = dataToUse.map(data => data.queries)

  // Define your data
  const data = {
    labels: days,
    datasets: [
      {
        label: 'Daily Requests',
        backgroundColor: '#B7926B',
        borderColor: '#B7926B',
        borderWidth: 1,
        hoverBackgroundColor: '#B7926B',
        hoverBorderColor: '#B7926B',
        data: requests,
      },
    ],
  };

   // Define your options with custom axis labels
   const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Requests', // Y-axis label
        },
      },
      x: {
        title: {
          display: true,
          text: 'Day of the Week', // X-axis label
        },
      },
    },
  };

  // Use useRef to get a reference to the canvas element
  const chartRef = useRef(null);

  useEffect(() => {
    let chartInstance = null;

    if (chartRef.current) {
      // Destroy the existing chart if it exists
      if (chartInstance) {
        chartInstance.destroy();
      }

      // Create a new chart instance
      chartInstance = new Chart(chartRef.current, {
        type: 'bar',
        data: data,
        options: options,
      });
    }

    return () => {
      // Cleanup: destroy the chart instance when the component unmounts
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [data, options]);

  return (
    <div>
      <h2 className='text-center text-primary font-bold text-xl'>Service Requests</h2>
      <canvas ref={chartRef} width="400" height="200"></canvas>
    </div>
  );
};

function returnPastSevenDays(queries) {
    const today = new Date()
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6); // Get the date 7 days ago
  
    // Create an array to store the result for each day, initializing with 0 queries
    const result = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(sevenDaysAgo);
      date.setDate(sevenDaysAgo.getDate() + i);
  
      // Get the day of the week name
      const dayOfWeek = getDayLabel(date, today)
      result.push({ dayOfWeek, queries: 0 });
    }
  
    for (let i = 0; i < queries.length; i++) {
      const query = queries[i];
      const queryDate = new Date(query.createdAt);
  
      if (queryDate >= sevenDaysAgo) {
        // Get the day of the week name

        const dayOfWeek = getDayLabel(queryDate, today)
  
        // Find the corresponding entry in the result array and increment the count
        const matchingEntry = result.find((entry) => entry.dayOfWeek === dayOfWeek);
        if (matchingEntry) {
          matchingEntry.queries++;
        }
      }
    }
  
    return result;
}

    // Helper function to get the day label
    function getDayLabel(date, today) {
        if (date.toDateString() === today.toDateString()) {
            return 'Today';
        } else if (date.toDateString() === new Date(today - 86400000).toDateString()) {
            return 'Yesterday';
        } else {
            return getDayOfWeekName(date.getDay());
        }
    }
  
  // Helper function to get the day of the week name
  function getDayOfWeekName(dayOfWeek) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[dayOfWeek];
  }

export default BarChart;
