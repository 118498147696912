import React, { useEffect, useState } from 'react';
import connectToSocketIo from './connect';
import { useNavigate } from 'react-router-dom';
import icon from './dial.png'
import { toast } from 'react-toastify';
import liveChat from '../sounds/livechat.mp3'

const Notifications = ({getNotifications, setInitializeFetchQueries}) => {
    const navigate = useNavigate();
    const [notificationPermission, setNotificationPermission] = useState(null);
    const audio = new Audio(liveChat)

    useEffect(() => {
        initializeSocket();
        // Check notification permission on component mount
        checkNotificationPermission();
    }, []);

    async function checkNotificationPermission() {
        if ('Notification' in window) {
            const permission = await Notification.requestPermission();
            setNotificationPermission(permission);
        }
    }

    async function initializeSocket() {
        try {
            const { connected, socket } = await connectToSocketIo();
            if (!connected) {
                navigate('/login', { replace: true });
                return;
            }

            // Add event listener for "query notification" event
            socket.on('query_notification', (data) => {
                setInitializeFetchQueries(true)
                if (notificationPermission === 'granted') {
                    // Create and display the notification
                    new Notification('New Notification', {
                        body: data.message,
                        icon: icon, // Replace with your icon URL
                    });
                }

                // push toast notification
                toast.info(data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: false,
                    closeButton: true
                })
                
                getNotifications() // get all the notifications
                audio.play()
            });

            // Add event listener for "Incident report" event
            socket.on('incident_report', (data) => {
                if (notificationPermission === 'granted') {
                    // Create and display the notification
                    new Notification('New Notification', {
                        body: data.message,
                        icon: icon, // Replace with your icon URL
                    });
                }

                // push toast notification
                toast.warn(data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: false,
                    closeButton: true
                })

                getNotifications() // get all the notifications
                audio.play()
            });

            return socket;
        } catch (error) {
            // Handle any errors that occur during the connection
            console.error('Socket connection error:', error);
        }
    }

    return (
        <div className="absolute"></div>
    );
};

export default Notifications;
